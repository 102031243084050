import { MonitoringService } from "src/services";
import { FUNCHandleRequest, loadingBar } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actGetDataMonitorings = (idKecamatan, tahun) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      MonitoringService.getData(idKecamatan, tahun).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_MONITORING", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetDataSubMonitorings = (idKecamatan, kode, tahun) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      MonitoringService.getDataSub(idKecamatan, kode, tahun).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_SUB_MONITORING", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const resetDataMonitorings = () => ({
  type: 'RESET_DATA_MONITORINGS',
});
export const resetDataSubMonitorings = () => ({
  type: 'RESET_DATA_SUB_MONITORINGS',
});