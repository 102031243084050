import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionTable from "./parts/SectionTable";
import { useHistory, useParams } from "react-router-dom";

const LayerList = () => {
  const history = useHistory();
  const params = useParams();
  return (
    <LayoutClient
      page="Data Map Layer"
      breadcrumb={[
        { name: "Data Map Grup", navigate: "/layergrup" },
        { name: "Page", navigate: "" },
      ]}
    >
      <button
        onClick={() => history.push(`/layergrup/layer/${params.groupid}/add`)}
        className="btn btn-success mb-3"
      >
        <i className="fa fa-plus mr-1" /> Tambah Layer
      </button>
      <SectionTable />
    </LayoutClient>
  );
};

export default LayerList;
