/* eslint-disable array-callback-return */
import { UserService } from "src/services";
import { FUNCArraySelectId, FUNCHandleRequest, loadingBar } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actUserSession = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      UserService.getInfoUser().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_USER_SESSION", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserInfo = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      UserService.getInfoUser().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_INFO_USER", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserGetAll = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      UserService.getAll().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_USERS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "data not found")
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserGetByStatus = (status) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      UserService.getByStatus(status).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_USERS", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserGetById = (id, history = null) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      UserService.getById(id).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_USER", payload: data.Data });
          if (history) history.push(`/users/edit/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserGetRoles = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      UserService.getRoles().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_ROLES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actUserGetJenisFiles = () => {
  return (dispatch) => {
    let json = [
      {
        Id: 1,
        Code: "string",
        Name: "Photo",
      },
      {
        Id: 2,
        Code: "string",
        Name: "Surat Tugas",
      },
    ];
    dispatch({
      type: "DATA_USER_JENIS_FILES",
      payload: FUNCArraySelectId(json),
    });
  };
};

export const actUserAdd = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("username", iData.username);
    fd.append("email", iData.email);
    fd.append("firstName", iData.firstName);
    fd.append("middleName", iData.middleName || "");
    fd.append("lastName", iData.lastName || "");
    fd.append("address", iData.address);
    fd.append("phoneNumber", iData.phoneNumber);
    fd.append("mobileNumber", iData.mobileNumber);
    if (iData.files)
      iData.files?.map((item, index) => {
        if (item) {
          fd.append(`files[${index}].Type`, item.type);
          fd.append(`files[${index}].Title`, item.title);
          fd.append(`files[${index}].FileUpload`, item.fileUpload);
        }
      });
    iData.roles?.map((item, index) => {
      fd.append(`roles[${index}]`, item.value);
    });
    FUNCHandleRequest(() =>
      UserService.add(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", "Pengguna berhasil dibuat!", "success").then(
            () => (window.location = "/users")
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserEdit = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("id", iData.id);
    fd.append("username", iData.username);
    fd.append("email", iData.email);
    fd.append("firstName", iData.firstName);
    fd.append("middleName", iData.middleName || "");
    fd.append("lastName", iData.lastName || "");
    fd.append("address", iData.address);
    fd.append("phoneNumber", iData.phoneNumber);
    fd.append("mobileNumber", iData.mobileNumber);

    if (iData.files) {
      var i = 0;
      iData.files?.map((item) => {
        if (item) {
          fd.append(`files[${i}].Type`, item.type);
          fd.append(`files[${i}].Title`, item.title);
          fd.append(`files[${i}].FileUpload`, item.fileUpload);
          i++;
        }
      });
    }
    iData.roles?.map((item, index) => {
      fd.append(`roles[${index}]`, item.value);
    });
    FUNCHandleRequest(() =>
      UserService.edit(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Pengguna berhasil diperbarui!",
            "success"
          ).then(() => (window.location = "/users"));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserDelete = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus pengguna ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          UserService.delete(fd).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Pengguna berhasil dihapus!",
                "success"
              ).then(() => dispatch(actUserGetAll()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actUserActivate = (userId) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan aktivasi pengguna ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("userId", userId);
        FUNCHandleRequest(() =>
          UserService.activate(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Pengguna berhasil di aktivasi!",
                "success"
              ).then(() => dispatch(actUserGetAll()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actUserDeactivate = (userId) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan deaktivasi pengguna ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("userId", userId);
        FUNCHandleRequest(() =>
          UserService.deactivate(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Pengguna berhasil di deaktivasi!",
                "success"
              ).then(() => dispatch(actUserGetAll()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actUserChangePassword = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("OldPassword", iData.OldPassword);
    fd.append("NewPassword", iData.NewPassword);
    fd.append("ConfirmPassword", iData.ConfirmPassword);
    FUNCHandleRequest(() =>
      UserService.changePassword(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Password berhasil diperbarui, silahkan login kembali!",
            "success"
          ).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actUserEditProfile = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("username", iData.username);
    fd.append("email", iData.email);
    fd.append("firstName", iData.firstName);
    fd.append("middleName", iData.middleName || "");
    fd.append("lastName", iData.lastName || "");
    fd.append("address", iData.address);
    fd.append("phoneNumber", iData.phoneNumber);
    fd.append("mobileNumber", iData.mobileNumber);
    if (iData.files) {
      var i = 0;
      iData.files?.map((item) => {
        if (item) {
          fd.append(`files[${i}].Type`, item.type);
          fd.append(`files[${i}].Title`, item.title);
          fd.append(`files[${i}].FileUpload`, item.fileUpload);
          i++;
        }
      });
    }
    iData.roles?.map((item, index) => {
      fd.append(`roles[${index}]`, item.value);
    });
    FUNCHandleRequest(() =>
      UserService.editProfile(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Profil Pengguna berhasil diperbarui!",
            "success"
          ).then(() => (window.location = "/settings/edit-profile"));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetMenus = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      UserService.getMenus().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          var dataArr = [];
          // dataArr.push({
          //   number: "1",
          //   name: "Dashboard",
          //   icon: "icon-home",
          //   to: "/dashboard",
          //   route: "",
          //   customid: null,
          //   tag: "CSidebarNavItems",
          //   childrens: [],
          // });
          data.Data.map((item) => {
            dataArr.push(item);
          });
          dispatch({ type: "DATA_MENUS", payload: dataArr });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
