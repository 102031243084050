import React from "react";
import { Video } from "src/components/molecules";
import { MapContainer, MapContainerTester } from "src/components/organisms";

const SectionContent = () => {
  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card">
          <div className="header">
            <h2>Map Page</h2>
          </div>
          <div className="body">
            <div style={{ height: "60vh" }}>
              <MapContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContent;
