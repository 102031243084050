/* eslint-disable import/no-anonymous-default-export */
import { instanceAuth } from "src/utils";

class MonitoringService {
  getData(idKecamatan, tahun) {

    return instanceAuth.get(`/monitoring/GetData?idKecamatan=${idKecamatan ?? 'no-param'}&tahun=${tahun ?? 0}`);
  }
  getDataSub(idKecamatan, kode, tahun) {
    return instanceAuth.get(`/monitoring/GetDataSub?idKecamatan=${idKecamatan ?? 'no-param'}&kode=${kode ?? 'no-param'}&tahun=${tahun ?? 0}`);
  }
}

export default new MonitoringService();
