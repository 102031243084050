import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionForm from "./parts/SectionForm";
import { useDispatch, useSelector } from "react-redux";
import { actGetKuisionerById } from "src/redux/actions";
import { useParams } from "react-router-dom";

const KuisionerAdd = (props) => {
  let { customid } = useParams();
  const jenisKuisionerId = customid;
  const { dataKuisionerById } = useSelector((state) => state.kuisionerReducer);
  const dispatch = useDispatch();

  const path = props?.location?.pathname;
  const modifiedPath = path.substring(0, path.lastIndexOf("/"));

  useEffect(() => {
    dispatch(actGetKuisionerById(jenisKuisionerId));
  }, [dispatch, jenisKuisionerId]);

  return (
    <LayoutClient
      page={`Tambah Responden ${dataKuisionerById?.NamaPendek}`}
      breadcrumb={[
        {
          name: dataKuisionerById?.NamaPendek,
          navigate: `${modifiedPath}/list`,
        },
        { name: "Tambah Responden", navigate: "" },
      ]}
    >
      <SectionForm myVar={jenisKuisionerId} />
    </LayoutClient>
  );
};

export default KuisionerAdd;
