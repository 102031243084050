import {
  Blank,
  ChangePassword,
  Dashboard,
  EditProfile,
  Map,
  UserAdd,
  UserEdit,
  UserList,
  SurveyList,
  SurveyAdd,
  SurveyEdit,
  KuisionerList,
  KuisionerAdd,
  KuisionerEdit,
  ManageKuisionerList,
  ManageKuisionerEdit,
  ManageSurveyList,
  ManageSurveyEdit,
  IntegrasiSatudata,
  SurveyView,
  KuisionerResponden,
  KuisionerDetail,
  LayerGroupList,
  LayerList,
  LayerGroupAdd,
  LayerGroupEdit,
  LayerAdd,
  LayerEdit,
  MonitoringList,
} from "src/screens/client";

const routes_client = [
  {
    path: "dashboard",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "settings/blank",
    exact: true,
    Component: Blank,
  },
  {
    path: "settings/change-password",
    exact: true,
    Component: ChangePassword,
  },
  {
    path: "settings/edit-profile",
    exact: true,
    Component: EditProfile,
  },
  {
    path: "settings/map",
    exact: true,
    Component: Map,
  },
  {
    path: "users",
    exact: true,
    Component: UserList,
  },
  {
    path: "users/add",
    exact: true,
    Component: UserAdd,
  },
  {
    path: "users/edit/:id",
    exact: true,
    Component: UserEdit,
  },
  {
    path: "layergrup",
    exact: true,
    Component: LayerGroupList,
  },
  {
    path: "layergrup/add",
    exact: true,
    Component: LayerGroupAdd,
  },
  {
    path: "layergrup/edit/:id",
    exact: true,
    Component: LayerGroupEdit,
  },
  {
    path: "layergrup/layer/:groupid",
    exact: true,
    Component: LayerList,
  },
  {
    path: "layergrup/layer/:groupid/add",
    exact: true,
    Component: LayerAdd,
  },
  {
    path: "layergrup/layer/:groupid/edit/:id",
    exact: true,
    Component: LayerEdit,
  },
  {
    path: "survey/:customid/add",
    exact: true,
    Component: SurveyAdd,
  },
  {
    path: "survey/:customid/edit/:id",
    exact: true,
    Component: SurveyEdit,
  },
  {
    path: "survey/:customid/detail/:id",
    exact: true,
    Component: SurveyView,
  },
  {
    path: "survey/:customid/list",
    exact: true,
    Component: SurveyList,
  },
  {
    path: "kuisioners",
    exact: true,
    Component: ManageKuisionerList,
  },
  {
    path: "kuisioners/edit/:id",
    exact: true,
    Component: ManageKuisionerEdit,
  },
  {
    path: "surveys",
    exact: true,
    Component: ManageSurveyList,
  },
  {
    path: "surveys/edit/:id",
    exact: true,
    Component: ManageSurveyEdit,
  },
  {
    path: "kuisioner/:customid/add",
    exact: true,
    Component: KuisionerAdd,
  },
  {
    path: "kuisioner/:customid/edit/:id",
    exact: true,
    Component: KuisionerEdit,
  },
  {
    path: "kuisioner/:customid/detail/:id",
    exact: true,
    Component: KuisionerDetail,
  },
  {
    path: "kuisioner/:customid/responden/:id",
    exact: true,
    Component: KuisionerResponden,
  },
  {
    path: "kuisioner/:customid/list",
    exact: true,
    Component: KuisionerList,
  },
  {
    path: "integrasi/:customid/list",
    exact: true,
    Component: IntegrasiSatudata,
  },
  {
    path: "monitoring",
    exact: true,
    Component: MonitoringList,
  },];

export default routes_client;
