const initialState = {
  dataMonitoringKecamatans: null,
  dataSubMonitorings: null
};

const monitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_MONITORING":
      return { ...state, dataMonitoringKecamatans: action.payload };
    case "DATA_SUB_MONITORING":
      return { ...state, dataSubMonitorings: action.payload };
    case 'RESET_DATA_MONITORINGS':
      return {
        ...state,
        dataMonitoringKecamatans: null,
      };
    case 'RESET_DATA_SUB_MONITORINGS':
      return {
        ...state,
        dataSubMonitorings: null,
      };
    default:
      return state;
  }
};

export default monitoringReducer;
