import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "src/components/molecules";
import {
  actUserActivate,
  actUserDeactivate,
  actUserDelete,
  actUserGetAll,
  actUserGetById,
} from "src/redux/actions";
import { useHistory } from "react-router-dom";
import { FUNCSetFullName } from "src/utils";
import { Tooltip } from "src/components/atoms";
import { IMGUserEmpty } from "src/assets/images/dummy";

const SectionTable = () => {
  const history = useHistory();
  const dataUsers = useSelector((state) => state.userReducer.dataUsers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actUserGetAll());
  }, [dispatch]);

  const btnEdit = (id) => dispatch(actUserGetById(id, history));
  const btnDel = (id) => dispatch(actUserDelete(id));
  const btnActivate = (id) => dispatch(actUserActivate(id));
  const btnDeactivate = (id) => dispatch(actUserDeactivate(id));

  const jsonColumn = [
    {
      name: "Id",
      header: "AKSI",
      selector: (row) => (
        <>
          <button
            onClick={() => btnEdit(row.getValue())}
            className="btn btn-sm btn-info mr-1"
          >
            <i className="fa fa-pencil mr-1" /> Edit
          </button>
          <button
            onClick={() => btnDel(row.getValue())}
            className="btn btn-sm btn-danger mr-1"
          >
            <i className="fa fa-trash mr-1" />
            Hapus
          </button>
        </>
      ),
    },
    {
      name: "Username",
      header: "USERNAME",
    },
    {
      name: "FirstName",
      header: "NAMA",
      selector: ({ row }) =>
        FUNCSetFullName(
          row.original.FirstName,
          row.original.MiddleName,
          row.original.LastName
        ),
    },
    {
      name: "UrlPath",
      header: "FOTO",
      selector: ({ row }) => (
        <img
          alt="img-user"
          className="media-object rounded-circle"
          src={`${row.original.UrlPath}/${row.original.FotoProfile}`}
          style={{ objectFit: "cover", width: 30, height: 30 }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = IMGUserEmpty;
          }}
        />
      ),
    },
    {
      name: "Status",
      header: "STATUS",
      selector: ({ row }) =>
        row.original.Status === 1 ? (
          <Tooltip
            position="top"
            id="tooltip-top"
            desc={
              <span>
                status akun ini <b>aktif</b>, anda dapat menonaktifkan dengan
                klik tombol nya
              </span>
            }
          >
            <span
              onClick={() => {
                btnDeactivate(row.original.Id);
              }}
              className="badge badge-primary cursor-pointer"
            >
              Aktif
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            position="top"
            id="tooltip-top"
            desc={
              <span>
                status akun ini <b>tidak aktif</b>, anda dapat mengaktifkan
                dengan klik tombol nya
              </span>
            }
          >
            <span
              onClick={() => {
                btnActivate(row.original.Id);
              }}
              className="badge badge-danger cursor-pointer"
            >
              Non-Aktif
            </span>
          </Tooltip>
        ),
    },
    {
      name: "Email",
      header: "EMAIL",
    },
    {
      name: "MobileNumber",
      header: "NO. HP",
    },
    {
      name: "LastLogin",
      header: "TERAKHIR LOGIN",
    },
    {
      name: "Address",
      header: "ALAMAT",
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Tabel Data</h2>
          </div>
          <div className="body">
            <DataTable data={dataUsers} column={jsonColumn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTable;
