import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actLogin, actFormLogin } from "src/redux/actions";
import { IMGLogo } from "src/assets/images/dummy";

const SectionForm = () => {
  const formLogin = useSelector((state) => state.authReducer.formLogin);
  const dispatch = useDispatch();

  return (
    <div className="auth-box">
      <div className="card">
        <div className="header">
          <img
            src={IMGLogo}
            alt="Survey"
            style={{
              width: "100%",
              height: "80px",
              objectFit: "contain",
            }}
          />
          <p className="lead text-center">Masuk Kehalaman Web Admin</p>
        </div>
        <div className="body">
          <div className="form-auth-small">
            <div className="form-group">
              <label className="control-label sr-only">Email</label>
              <input
                className="form-control"
                id="signin-email"
                placeholder="Email / Username"
                type="email"
                value={formLogin?.usernameOrEmail}
                onChange={(val) => {
                  dispatch(actFormLogin("usernameOrEmail", val.target.value));
                }}
              />
            </div>
            <div className="form-group">
              <label className="control-label sr-only">Password</label>
              <input
                className="form-control"
                id="signin-password"
                placeholder="Password"
                type="password"
                value={formLogin?.password}
                onChange={(val) => {
                  dispatch(actFormLogin("password", val.target.value));
                }}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={() => dispatch(actLogin(formLogin))}
            >
              Masuk
            </button>
            <div className="bottom">
              <span className="helper-text m-b-10">
                <i className="fa fa-lock"></i>{" "}
                <a href={`${process.env.PUBLIC_URL}/forgot-password`}>
                  Lupa Password?
                </a>
              </span>
              <span>
                Belum memiliki akun? <a href="registration">Buat Sekarang</a>
              </span>
              <hr />
              <p className="helper-text mt-3">
                <i className="fa fa-android"></i>{" "}
                <a
                  href="https://s.id/MubaSurveiApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Aplikasi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
