import { SdiService } from "src/services";
import {
  FUNCArraySelectId,
  FUNCArraySelectKode,
  FUNCHandleRequest,
  loadingBar,
} from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actGetSdiPublicationDatasetTypes = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getPublicationDatasetTypes().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_PUBLICATION_DATASET_TYPES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiLicenses = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getLicenses().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_LICENSES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiScopes = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getDataScopes().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_SCOPES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiFrequencies = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getFrequencies().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_FREQUENCIES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiPeriod = (frequencyId) => {
  return (dispatch) => {
    const periods = {
      Semester: [
        { Id: 1, Name: "Semester 1" },
        { Id: 2, Name: "Semester 2" },
      ],
      Kwartal: [
        { Id: 1, Name: "1 Kwartal" },
        { Id: 2, Name: "2 Kwartal" },
        { Id: 3, Name: "3 Kwartal" },
      ],
      Triwulan: [
        { Id: 1, Name: "1 Triwulan" },
        { Id: 2, Name: "2 Triwulan" },
        { Id: 3, Name: "3 Triwulan" },
        { Id: 4, Name: "4 Triwulan" },
      ],
      Bulan: [
        { Id: 1, Name: "Januari" },
        { Id: 2, Name: "Februari" },
        { Id: 3, Name: "Maret" },
        { Id: 4, Name: "April" },
        { Id: 5, Name: "Mei" },
        { Id: 6, Name: "Juni" },
        { Id: 7, Name: "Juli" },
        { Id: 8, Name: "Agustus" },
        { Id: 9, Name: "September" },
        { Id: 10, Name: "Oktober" },
        { Id: 11, Name: "November" },
        { Id: 12, Name: "Desember" },
      ],
    };

    if (frequencyId !== "Tahun") {
      dispatch({
        type: "DATA_SDI_PERIOD",
        payload: FUNCArraySelectId(periods[frequencyId]),
      });
    } else {
      dispatch({
        type: "DATA_SDI_PERIOD",
        payload: [],
      });
    }
  };
};

export const actGetSdiTags = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getTags().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_TAGS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiAgencies = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getAgencies().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_AGENCIES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiPublicationDocumentTypes = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getPublicationDocumentTypes().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_PUBLICATION_DOCUMENT_TYPES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiTopics = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getDataTopics().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_TOPICS",
            payload: FUNCArraySelectKode(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiCategories = (topicId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getDataCategories(topicId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_CATEGORIES",
            payload: FUNCArraySelectKode(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiDatasetTypes = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getDatasetTypes().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_DATASET_TYPES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiFieldTypes = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getFieldTypes().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_FIELD_TYPES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSdiYears = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SdiService.getYears().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_YEARS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actTagAdd = (name, onModal) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("name", name);

    FUNCHandleRequest(() =>
      SdiService.saveTag(fd).then((res) => {
        var data = res.data;
        onModal();
        if (data.IsSuccess) {
          dispatch(actGetSdiTags());
          Swal.fire("Berhasil", "Tag berhasil dibuat!", "success");
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actDatasetAdd = (iData, type) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("JenisSurveyId", iData.jenisSurveyId[0].value);
    fd.append("Title", iData.title);
    fd.append("PublicationTypeId", iData.publicationType[0].value);
    fd.append("LicenseId", iData.licenseId[0].value);
    fd.append("DataScopeId", iData.dataScopeId[0].value);
    fd.append("DataSource", iData.dataSource);
    fd.append("DatasetTypeId", iData.datasetPublicationDocumentTypeId[0].value);
    fd.append("FrequencyId", iData.frequencyId[0].value);
    let period = iData.frequencyId[0].value;
    if (iData.frequencyId[0].value !== 1) period = iData.period[0].value;
    fd.append("Period", period);
    iData.tagIds?.map((item, index) =>
      fd.append(`TagIds[${index}]`, item.value)
    );

    fd.append("Description", iData.description);
    fd.append("AgencyId", iData.agencyId[0].value);
    fd.append("DataScopeId", iData.datasetTopicId[0].value);
    fd.append("DatasetCategoryId", iData.datasetCategoryId[0].value);
    fd.append("Year", iData.year[0].value);

    iData.attributes?.map((item, index) => {
      fd.append(`Fields[${index}].Title`, item.title);
      fd.append(`Fields[${index}].FieldTypeId`, item.fieldTypeId[0].value);
      fd.append(`Fields[${index}].FieldLength`, item.fieldLength);
      fd.append(
        `Fields[${index}].PublicationType`,
        item.publicationType[0].value
      );
      fd.append(`Fields[${index}].Description`, item.description);
    });

    FUNCHandleRequest(() =>
      SdiService.saveDataset(fd, type).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Dataset berhasil dikirim ke portal satu data!",
            "success"
          ).then(() => (window.location = `/integrasi/${type}/list`));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
