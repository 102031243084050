import React from "react";
import { useDispatch } from "react-redux";
import { TextArea, TextInput } from "src/components/atoms";
import { useForm } from "react-hook-form";
import { actAddLayerGroup } from "src/redux/actions";

const SectionForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const btnSave = (data) => dispatch(actAddLayerGroup(data));

  return (
    <div className="row clearfix">
      <div className="col-lg-6 col-md-6 mx-auto">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Map Grup</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-12">
                  <TextInput
                    label={"Nama"}
                    hook={register("name", { required: true })}
                    isError={errors.name}
                  />
                  <TextArea
                    label={"Deskripsi"}
                    hook={register("description", { required: true })}
                    isError={errors.description}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Reset</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
