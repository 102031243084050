const initialState = {
  formMapLayerGroup: {
    id: "",
    name: "",
    description: "",
  },
  formMapLayer: {
    groupId: "",
    type: "",
    url: "",
    name: "",
    title: "",
    description: "",
    thumbnail: "",
    status: "",
  },
  dataMapLayerGroups: [],
  dataMapLayerGroup: null,
  dataMapLayers: [],
  dataMapLayer: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_LAYER_GROUP":
      return {
        ...state,
        formMapLayerGroup: {
          ...state.formMapLayerGroup,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_LAYER_GROUP":
      return { ...state, formMapLayerGroup: initialState.formMapLayerGroup };
    case "FORM_LAYER":
      return {
        ...state,
        formMapLayer: {
          ...state.formMapLayer,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_LAYER":
      return { ...state, formMapLayer: initialState.formMapLayer };
    case "DATA_MAP_LAYER_GROUPS":
      return { ...state, dataMapLayerGroups: action.payload };
    case "DATA_MAP_LAYER_GROUP":
      return { ...state, dataMapLayerGroup: action.payload };
    case "DATA_MAP_LAYERS":
      return { ...state, dataMapLayers: action.payload };
    case "DATA_MAP_LAYER":
      return { ...state, dataMapLayer: action.payload };
    default:
      return state;
  }
};

export default authReducer;
