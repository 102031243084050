import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionForm from "./parts/SectionForm";

const ManageKuisionerEdit = () => {
  return (
    <LayoutClient
      page="Perbarui Data Kuisioner"
      breadcrumb={[
        { name: "Data Kuisioner", navigate: "/kuisioners" },
        { name: "Page", navigate: "" },
      ]}
    >
      <SectionForm />
    </LayoutClient>
  );
};

export default ManageKuisionerEdit;
