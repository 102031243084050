import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionView from "./parts/SectionView";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actGetJenisSurveyById } from "src/redux/actions";

const SurveyDetail = (props) => {
  let { customid } = useParams();
  const jenisSurveyId = customid;

  const dataJenisSurveyById = useSelector(
    (state) => state.refReducer.dataJenisSurveyById
  );
  const dispatch = useDispatch();

  const path = props?.location?.pathname;
  const parts = path.split("/");
  const modifiedPath = `/${parts[1]}/${customid}`;

  useEffect(() => {
    dispatch(actGetJenisSurveyById(jenisSurveyId));
  }, [dispatch, jenisSurveyId]);

  return (
    <LayoutClient
      page={`Lihat Data ${dataJenisSurveyById?.Namapendek}`}
      breadcrumb={[
        {
          name: dataJenisSurveyById?.Namapendek,
          navigate: `${modifiedPath}/list`,
        },
        { name: "Lihat Data", navigate: "" },
      ]}
    >
      <SectionView />
    </LayoutClient>
  );
};

export default SurveyDetail;
