import { WilService } from "src/services";
import { FUNCArraySelectId, FUNCHandleRequest } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actGetProvinsis = () => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getProvinsis().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_PROVINSIS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetKabupatens = (provinsiId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getKabupatens(provinsiId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_KABUPATENS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetKecamatans = (kabupatenId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getKecamatans(kabupatenId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_KECAMATANS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetDesas = (kecamatanId) => {
  return (dispatch) => {
    dispatch({
      type: "DATA_WIL_DESAS",
      payload: [],
    });
    FUNCHandleRequest(() =>
      WilService.getDesas(kecamatanId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_DESAS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetRws = (desaId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getRws(desaId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_RWS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetRts = (rwId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getRts(rwId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_WIL_RTS",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetWilReferences = (rwId) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      WilService.getWilReferences(rwId).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          // let dataProvArr = data.Data["ref_provinsi"].map((item) => {
          //   return {
          //     ...item,
          //     value: item.Kode,
          //     label: item.Nama,
          //   };
          // });
          // let dataKabArr = data.Data["ref_kabupaten"].map((item) => {
          //   return {
          //     ...item,
          //     value: item.Kode,
          //     label: item.Nama,
          //   };
          // });
          // let dataKecArr = data.Data["ref_kecamatan"].map((item) => {
          //   return {
          //     ...item,
          //     value: item.Kode,
          //     label: item.Nama,
          //   };
          // });
          // let dataKecArr = data.Data["ref_kecamatan"].map((item) => {
          //   return {
          //     ...item,
          //     value: item.Kode,
          //     label: item.Nama,
          //   };
          // });
          dispatch({
            type: "DATA_WIL_REFERENCES",
            payload: data.Data,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
