const initialState = {
  formLogin: {
    usernameOrEmail: "",
    password: "",
    // usernameOrEmail: "admin",
    // password: "SurveyApp2023",
  },
  formRegister: {
    username: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    address: "",
    files: [],
  },
  formForgetPassword: {
    email: "",
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FORM_LOGIN":
      return {
        ...state,
        formLogin: {
          ...state.formLogin,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_LOGIN":
      return { ...state, formLogin: initialState.formLogin };
    case "FORM_REGISTER":
      return {
        ...state,
        formRegister: {
          ...state.formRegister,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_REGISTER":
      return { ...state, formRegister: initialState.formRegister };

    case "FORM_FORGET_PASSWORD":
      return {
        ...state,
        formForgetPassword: {
          ...state.formForgetPassword,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORGET_PASSWORD":
      return { ...state, formForgetPassword: initialState.formForgetPassword };

    default:
      return state;
  }
};

export default authReducer;
