import { combineReducers } from "redux";
import loginReducer from "./library/loginReducer";
import navigationReducer from "./library/navigationReducer";
import analyticalReducer from "./library/analyticalReducer";
import demographicReducer from "./library/demographicReducer";
import ioTReducer from "./library/ioTReducer";
import mailInboxReducer from "./library/mailInboxReducer";
import UIElementsReducer from "./library/UIElementsReducer";

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import refReducer from "./refReducer";
import wilReducer from "./wilReducer";
import kuisionerReducer from "./kuisionerReducer";
import homeReducer from "./homeReducer";
import surveyReducer from "./surveyReducer";
import sdiReducer from "./sdiReducer";
import mapReducer from "./mapReducer";
import monitoringReducer from "./monitoringReducer";

export default combineReducers({
  loginReducer,
  navigationReducer: navigationReducer,
  analyticalReducer: analyticalReducer,
  demographicReducer: demographicReducer,
  ioTReducer: ioTReducer,
  mailInboxReducer: mailInboxReducer,
  UIElementsReducer: UIElementsReducer,
  authReducer,
  userReducer,
  refReducer,
  wilReducer,
  kuisionerReducer,
  homeReducer,
  surveyReducer,
  sdiReducer,
  mapReducer,
  monitoringReducer
});
