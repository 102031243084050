import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actFormSurvey,
  actGetJenisSurveyById,
  actGetTemplateSurvey,
  actSurveyAdd,
} from "src/redux/actions";
import { useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Combobox,
  TextArea,
  TextInput,
  TextUpload,
} from "src/components/atoms";
import {
  FUNCFileConvertBase64,
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
  tipeComboWilDynamic,
  tipeInputSurvey,
} from "src/utils";
import { Modal } from "react-bootstrap";
import { MapContainerDragPoint } from "src/components/organisms";
import Swal from "sweetalert2/dist/sweetalert2";

const SectionForm = () => {
  const { customid } = useParams();

  let jenisSurveyId = customid;

  const dispatch = useDispatch();
  const { dataJenisSurveyById } = useSelector((state) => state.refReducer);
  const { dataSurveyTemplate } = useSelector((state) => state.surveyReducer);

  const [showModalMap, setShowModalMap] = useState(false);

  const hookForm = useForm();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
    reset,
  } = hookForm;

  const { fields: fieldsAtributs, append: appendAtributs } = useFieldArray({
    control,
    name: "Atributs",
  });
  const { fields: fieldsFiles, append: appendFiles } = useFieldArray({
    control,
    name: "Files",
  });

  useEffect(() => {
    dispatch(actGetJenisSurveyById(jenisSurveyId));
    dispatch(actGetTemplateSurvey(jenisSurveyId));
  }, [dispatch, jenisSurveyId]);

  useEffect(() => {
    reset();
    dataSurveyTemplate?.Atributs?.forEach((item) => {
      appendAtributs(item);
    });
    dataSurveyTemplate?.Files?.forEach((item) => {
      appendFiles(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSurveyTemplate]);

  const btnSave = (data) => {
    let atributs = data.Atributs.map((item) => {
      let value = item.StringValue;
      if (item.Atribut?.RefTables)
        if (item.StringValue) value = item.StringValue[0].value;

      return { ...item, StringValue: value };
    });
    let files = data.Files.map((item) =>
      Object.assign(item, {
        Nama: item.Base64 || item.Nama,
      })
    );
    let dataArr = {
      KodeData: data.kodeData,
      JenisSurveyId: jenisSurveyId,
      Longitude: parseFloat(data.longitude),
      Latitude: parseFloat(data.latitude),
      Atributs: atributs,
      Files: files,
    };
    dispatch(actSurveyAdd(dataArr, jenisSurveyId));
  };

  const onModalMap = () => {
    setShowModalMap(!showModalMap);
  };

  const savePointLatLon = () => {
    if (!getValues("latitudeUpdate")) {
      Swal.fire("Gagal", "Anda tidak melakukan perubahan lokasi!", "info");
      return;
    }
    setValue("latitude", getValues("latitudeUpdate"));
    setValue("longitude", getValues("longitudeUpdate"));
    onModalMap();
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card planned_task">
            <div className="header">
              <h2>Form Survey {dataJenisSurveyById?.Namapendek}</h2>
            </div>
            <div className="body">
              <form onSubmit={handleSubmit(btnSave)}>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      label={dataJenisSurveyById?.Ketkodedata?.toUpperCase()}
                      hook={register("kodeData", { required: true })}
                      isError={errors.kodeData}
                    />
                    <div className="row">
                      <div className="col-md-5">
                        <TextInput
                          label={"LONGITUDE"}
                          hook={register("longitude", { required: true })}
                          isError={errors.longitude}
                        />
                      </div>
                      <div className="col-md-5">
                        <TextInput
                          label={"LATITUDE"}
                          hook={register("latitude", { required: true })}
                          isError={errors.latitude}
                        />
                      </div>
                      <div className="col-md-2 d-flex items-align-center justify-content-center my-0">
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={() => onModalMap()}
                        >
                          <i className="fa fa-map"></i> <span>MAP</span>
                        </button>
                      </div>
                    </div>
                    <ContainerDynamicFormInput
                      data={fieldsAtributs}
                      hookForm={hookForm}
                    />
                  </div>
                  <div className="col-md-6">
                    <ContainerDynamicFormUpload
                      data={fieldsFiles}
                      hookForm={hookForm}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Reset</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal size={"xl"} show={showModalMap} onHide={onModalMap}>
        <Modal.Header closeButton>
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ height: "60vh" }}>
            <MapContainerDragPoint hookForm={hookForm} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            onClick={savePointLatLon}
          >
            <i className="fa fa-save"></i> <span>Simpan</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ContainerDynamicFormInput = ({ data, hookForm }) => {
  data = data.sort((a, b) => a.Atribut.Indexno - b.Atribut.Indexno);
  return (
    <div className="row">
      {data?.map((item, index) => {
        return (
          <SurveyFormInput
            key={index}
            index={index}
            attribut={item.Atribut}
            hookForm={hookForm}
          />
        );
      })}
    </div>
  );
};

const SurveyFormInput = ({ index, attribut, hookForm }) => {
  const [dataPilihans, setDataPilihans] = useState([]);

  const formSurvey = useSelector((state) => state.surveyReducer.formSurvey);
  const dispatch = useDispatch();
  const {
    register,
    control,
    formState: { errors },
  } = hookForm;

  useEffect(() => {
    const arrdataPilihans = [];
    attribut.RefTables?.Info?.forEach((item) => {
      arrdataPilihans.push({
        ...item,
        value: item.Code,
        label: item.Name,
      });
    });
    setDataPilihans(arrdataPilihans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribut]);

  useEffect(() => {
    if (attribut.Reftablesid === "ref_desa") {
      const arrdataPilihans = [];
      attribut.RefTables.Info?.forEach((item) => {
        if (
          parseInt(item.ParentCode) === parseInt(formSurvey?.dynamicKecamatan)
        )
          arrdataPilihans.push({
            ...item,
            value: item.Code,
            label: item.Name,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (attribut.Reftablesid === "ref_rw") {
      const arrdataPilihans = [];
      attribut.RefTables.Info?.forEach((item) => {
        if (parseInt(item.ParentCode) === parseInt(formSurvey?.dynamicDesa))
          arrdataPilihans.push({
            ...item,
            value: item.Code,
            label: item.Name,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    if (attribut.Reftablesid === "ref_rt") {
      const arrdataPilihans = [];
      attribut.RefTables.Info?.forEach((item) => {
        if (parseInt(item.ParentCode) === parseInt(formSurvey?.dynamicRw))
          arrdataPilihans.push({
            ...item,
            value: item.Code,
            label: item.Name,
          });
      });
      setDataPilihans(arrdataPilihans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSurvey]);

  const onWil = (val) => {
    val = val[0];
    if (attribut.Reftablesid === "ref_kecamatan")
      dispatch(actFormSurvey("dynamicKecamatan", val.Code));
    if (attribut.Reftablesid === "ref_desa")
      dispatch(actFormSurvey("dynamicDesa", val.Code));
    if (attribut.Reftablesid === "ref_rw")
      dispatch(actFormSurvey("dynamicRw", val.Code));
    if (attribut.Reftablesid === "ref_rt")
      dispatch(actFormSurvey("dynamicRt", val.Code));
  };

  const TextField = () => {
    const renderComboTableWilField = () => {
      return (
        <Controller
          control={control}
          name={`Atributs[${index}].StringValue`}
          render={({ field }) => (
            <Combobox
              label={tipeComboWilDynamic[attribut.RefTables.Name]}
              options={dataPilihans}
              // values={dataPilihans.filter((x) => x.ispilih)}
              onChange={(val) => {
                field.onChange(val);
                onWil(val);
              }}
            />
          )}
          rules={{
            required: false,
          }}
        />
      );
    };
    const renderComboTableField = () => {
      let dataCombos = [];
      attribut.RefTables.Info.map((item) => {
        dataCombos.push({
          label: item.Name,
          value: item.Code,
        });
      });
      return (
        <Controller
          control={control}
          name={`Atributs[${index}].StringValue`}
          render={({ field }) => (
            <Combobox
              label={attribut.Alias}
              options={dataCombos}
              onChange={(val) => {
                field.onChange(val);
              }}
            />
          )}
          rules={{
            required: false,
          }}
        />
      );
    };
    const renderNumberField = () => {
      return (
        <TextInput
          type="number"
          label={attribut.Alias?.toUpperCase()}
          hook={register(`Atributs[${index}].NumberValue`, {
            required: false,
          })}
          isError={errors?.Atributs?.[index]?.NumberValue}
        />
      );
    };
    const renderStringField = () => {
      if (attribut.Length < 200)
        return (
          <TextInput
            type="text"
            label={attribut.Alias?.toUpperCase()}
            hook={register(`Atributs[${index}].StringValue`, {
              required: false,
            })}
            isError={errors?.Atributs?.[index]?.StringValue}
          />
        );
      return (
        <TextArea
          label={attribut.Alias?.toUpperCase()}
          hook={register(`Atributs[${index}].StringValue`, {
            required: false,
          })}
          isError={errors?.Atributs?.[index]?.StringValue}
        />
      );
    };

    if (attribut.RefTables?.Istable === true) {
      return renderComboTableWilField();
    }
    if (attribut.RefTables?.Istable === false) {
      return renderComboTableField();
    }
    if (
      attribut.Type === tipeInputSurvey.double ||
      attribut.Type === tipeInputSurvey.integer
    ) {
      return renderNumberField();
    }
    if (attribut.Type === tipeInputSurvey.string) {
      return renderStringField();
    }

    return <></>;
  };
  return <div className="col-md-12">{TextField()}</div>;
};

const ContainerDynamicFormUpload = ({ data, hookForm }) => {
  return (
    <div className="row">
      {data?.map((item, index) => {
        return (
          <SurveyFormUpload
            key={index}
            index={index}
            attribut={item}
            hookForm={hookForm}
          />
        );
      })}
    </div>
  );
};

const SurveyFormUpload = ({ index, attribut, hookForm }) => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = hookForm;

  const onFile = async (val) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target, ["pdf", "png", "jpg", "jpeg"]);
    let fileUpload = val.target.files[0];
    let fileBase64 = await FUNCFileConvertBase64(fileUpload).then(
      (res) => res.split("base64,")[1]
    );
    let file = Object.assign(attribut, {
      JenisFileId: attribut.JenisFileId,
      Judul: attribut.JenisFile.Nama,
      FileUpload: fileUpload,
      Base64: fileBase64,
    });
    setValue(`Files[${index}]`, file, {
      shouldValidate: true,
    });
  };
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name={`Files[${index}].FileUpload`}
            render={({ field }) => (
              <TextUpload
                label={attribut.JenisFile.Nama?.toUpperCase()}
                name={field.name}
                fileSelect={getValues(`Files[${index}]`)?.FileUpload?.name}
                onChange={(val) => {
                  onFile(val);
                }}
                onDel={() =>
                  setValue(
                    `Files[${index}]`,
                    Object.assign(attribut, { FileUpload: null }),
                    {
                      shouldValidate: true,
                    }
                  )
                }
                isError={errors?.Files?.[index]?.FileUpload}
              />
            )}
            rules={{
              required: false,
            }}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label={`CATATAN`}
            hook={register(`Files[${index}].Catatan`, {
              required: false,
            })}
            isError={errors?.Files?.[index]?.Catatan}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
