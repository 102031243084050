import React from "react";
import { IMGLogo } from "src/assets/images/dummy";

export const Loader = ({ loading }) => {
  return (
    <div
      className="page-loader-wrapper bg-white"
      style={{ display: loading ? "block" : "none" }}
    >
      <div className="loader">
        <div className="m-t-30">
          <img src={IMGLogo} width="auto" height="100" alt="Survey" />
        </div>
        <p className="text-dark">Tunggu sebentar...</p>
      </div>
    </div>
  );
};
