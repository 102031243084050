import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { IMGLogo } from "src/assets/images/dummy";
import { actForgotPassword, actFormForgetPassword } from "src/redux/actions";

const SectionForm = () => {
  const formForgetPassword = useSelector(
    (state) => state.authReducer.formForgetPassword
  );

  const dispatch = useDispatch();

  return (
    <div className="auth-box">
      <div className="card">
        <div className="header">
          <img
            src={IMGLogo}
            alt="Lucid"
            style={{
              width: "100%",
              height: "80px",
              objectFit: "contain",
            }}
          />
          <p className="lead text-center">Lupa Password</p>
        </div>
        <div className="body">
          <p>
            Silakan masukkan alamat email Anda di bawah ini untuk menerima
            instruksi untuk mengatur ulang kata sandi.
          </p>
          <div className="form-auth-small ng-untouched ng-pristine ng-valid">
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Email"
                type="email"
                required={true}
                value={formForgetPassword?.email}
                onChange={(val) => {
                  dispatch(actFormForgetPassword("email", val.target.value));
                }}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={() => {
                dispatch(actForgotPassword(formForgetPassword));
              }}
            >
              LUPA PASSWORD
            </button>
            <div className="bottom">
              <span className="helper-text">
                Ingat Password? <a href="login">Masuk</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
