import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionStatistic from "./parts/SectionStatistic";
import SectionMap from "./parts/SectionMap";

const Dashboard = () => {
  return (
    <LayoutClient
      page="Dashboard"
      breadcrumb={[{ name: "Utama", navigate: "" }]}
    >
      <SectionStatistic />
      <SectionMap />
    </LayoutClient>
  );
};

export default Dashboard;
