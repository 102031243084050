import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actForgotPasswordConfirm } from "src/redux/actions";

import { IMGLogo } from "src/assets/images/dummy";
import { useParams } from "react-router-dom";

const SectionForm = () => {
  let { token } = useParams();
  const formForgetPassword = useSelector(
    (state) => state.authReducer.formForgetPassword
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actForgotPasswordConfirm(token));
  }, [dispatch, token]);

  return (
    <div className="auth-box-secondary">
      <div className="card">
        <div className="header">
          <img
            src={IMGLogo}
            alt="Lucid"
            style={{
              width: "100%",
              height: "80px",
              objectFit: "contain",
            }}
          />
          <p className="lead text-center">Konfirmasi Lupa Password</p>
        </div>
        <div className="body">
          <div className="form-auth-small ng-untouched ng-pristine ng-valid">
            <div className="row">
              <div className="col-md-12">
                {formForgetPassword?.msgConfirmPass === "success" && (
                  <div className="alert alert-success">
                    <b>Berhasil:</b> Password berhasil direset, silahkan cek
                    email untuk melihat password baru!
                  </div>
                )}
                {formForgetPassword?.msgConfirmPass === "error" && (
                  <div className="alert alert-danger">
                    <b>Gagal:</b> Token sudah expired!
                  </div>
                )}
              </div>
            </div>

            <div className="bottom">
              <span className="helper-text">
                Sudah memiliki akun? <a href="login">Login</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
