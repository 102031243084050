import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ComboboxRerender, TextDate, TextInput } from "src/components/atoms";
import { DataTableSsr } from "src/components/molecules";
import {
  actDownloadExcelSurvey,
  actDownloadFilesSurvey,
  actFormSurvey,
  actGetDesas,
  actGetKabupatens,
  actGetKecamatans,
  actGetProvinsis,
  actGetSurvey,
  actGetSurveys,
  actGetSurveysSearchBy,
  actSurveyDelete,
} from "src/redux/actions";
import { FUNCDateDmytoYmd } from "src/utils";

const SectionTable = ({ isDisabledByYear }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [selectIndex, setSelectIndex] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalFile, setShowModalFile] = useState(false);

  const { customid } = useParams();
  const history = useHistory();

  const {
    handleSubmit: handleSubmitDownload,
    control: controlDownload,
    register: registerDownload,
    setValue: setValueDownload,
    formState: { errors: errorsDownload },
    reset: resetDownload,
    getValues: getValuesDownload,
  } = useForm();

  const {
    handleSubmit: handleSubmitFilter,
    control: controlFilter,
    register: registerFilter,
    formState: { errors: errorsFilter },
    reset: resetFilter,
    setValue: setValueFilter,
    getValues: getValuesFilter,
  } = useForm();

  const {
    handleSubmit: handleSubmitFile,
    control: controlFile,
    register: registerFile,
    setValue: setValueFile,
    formState: { errors: errorsFile },
    reset: resetFile,
    getValues: getValuesFile,
  } = useForm();

  let jenisSurveyId = customid;

  const dispatch = useDispatch();
  const { dataSurveys, dataSurveyAttribut, formSurvey } = useSelector(
    (state) => state.surveyReducer
  );
  const dataJenisSurveyById = useSelector(
    (state) => state.refReducer.dataJenisSurveyById
  );
  const {
    dataWilProvinsis,
    dataWilKabupatens,
    dataWilKecamatans,
    dataWilDesas,
  } = useSelector((state) => state.wilReducer);

  useEffect(() => {
    dispatch({ type: "RESET_FORM_SURVEY" });
    resetDownload();
    resetFilter();
    dispatch(actGetProvinsis());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jenisSurveyId]);

  useEffect(() => {
    if (dataWilProvinsis.length !== 0)
      dispatch(actGetKabupatens(dataWilProvinsis[0].value));
  }, [dataWilProvinsis, dispatch]);
  useEffect(() => {
    if (dataWilKabupatens.length !== 0)
      dispatch(actGetKecamatans(dataWilKabupatens[0].value));
  }, [dataWilKabupatens, dispatch]);

  useEffect(() => {
    if (formSurvey?.filterId) {
      dispatch(
        actGetSurveysSearchBy(
          jenisSurveyId,
          pageIndex + 1,
          selectIndex,
          formSurvey?.filterId[0].value,
          formSurvey?.filterValue
        )
      );
    } else {
      dispatch(actGetSurveys(jenisSurveyId, pageIndex + 1, selectIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jenisSurveyId, pageIndex, selectIndex]);

  useEffect(() => {
    if (dataJenisSurveyById) {
      setValueDownload(
        "minDate",
        new Date(FUNCDateDmytoYmd(dataJenisSurveyById?.Tanggalmulai))
      );
      setValueDownload(
        "maxDate",
        new Date(FUNCDateDmytoYmd(dataJenisSurveyById?.Tanggalselesai))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataJenisSurveyById]);

  const onModal = () => setShowModal(!showModal);
  const onModalFilter = () => setShowModalFilter(!showModalFilter);
  const onModalFile = () => setShowModalFile(!showModalFile);

  const btnDownloadFiles = (data) => {
    setShowModalFile(!showModalFile);
    dispatch(actDownloadFilesSurvey(jenisSurveyId, data));
    resetFile();
  };

  const btnDownloadExcel = (data) => {
    setShowModal(!showModal);
    dispatch(actDownloadExcelSurvey(jenisSurveyId, data));
  };
  const btnFilter = (data) => {
    setShowModalFilter(!showModalFilter);
    let value = data.atributValue;
    if (data.atributId[0].value === "kecamatan") {
      value = data.atributValueWilKec[0].label;
    } else if (data.atributId[0].value === "desa") {
      value = data.atributValueWilDesa[0].label;
    }
    dispatch(actFormSurvey("filterId", data.atributId));
    dispatch(actFormSurvey("filterValue", value));
    dispatch(
      actGetSurveysSearchBy(
        jenisSurveyId,
        1,
        10,
        data.atributId[0].value,
        value
      )
    );
  };
  const btnResetFilter = () => {
    setPageIndex(0);
    setSelectIndex(10);
    dispatch(actGetSurveys(jenisSurveyId, 1, 10));
    dispatch(actFormSurvey("filterId", null));
    dispatch(actFormSurvey("filterValue", null));
    resetFilter();
  };

  const btnEdit = (id) =>
    dispatch(actGetSurvey(id, history, jenisSurveyId, "edit"));
  const btnDel = (id) => dispatch(actSurveyDelete(id, jenisSurveyId));
  const btnView = (id) =>
    dispatch(actGetSurvey(id, history, jenisSurveyId, "detail"));

  let jsonColumn = [
    {
      name: "Id",
      header: "AKSI",
      selector: (row) => (
        <>
          <button
            onClick={() => {
              btnEdit(row.getValue());
            }}
            className="btn btn-sm btn-info mr-1"
            disabled={isDisabledByYear}
          >
            <i className="fa fa-pencil mr-1" /> Edit
          </button>
          <button
            onClick={() => btnDel(row.getValue())}
            className="btn btn-sm btn-danger mr-1"
            disabled={isDisabledByYear}
          >
            <i className="fa fa-trash mr-1" />
            Hapus
          </button>
          <button
            onClick={() => btnView(row.getValue())}
            className="btn btn-sm btn-secondary mr-1"
          >
            <i className="fa fa-eye mr-1" />
            Lihat
          </button>
        </>
      ),
    },
    {
      name: "KodeData",
      header: dataJenisSurveyById?.Ketkodedata?.toUpperCase() || "KODE DATA",
    },
    {
      name: "Kecamatan",
      header: "KECAMATAN",
      selector: (row) => row.getValue() || "-",
    },
    {
      name: "Desa",
      header: "DESA",
      selector: (row) => row.getValue() || "-",
    },
    {
      name: "Longitude",
      header: "LONGITUDE",
      selector: (row) => <code>{row.getValue()}</code>,
    },
    {
      name: "Latitude",
      header: "LATITUDE",
      selector: (row) => <code>{row.getValue()}</code>,
    },
    {
      name: "Status",
      header: "STATUS",
      selector: (row) => (
        <>
          {row.getValue() === "New" && (
            <span className="badge badge-info">{row.getValue()}</span>
          )}
          {row.getValue() === "Approve" && (
            <span className="badge badge-success">{row.getValue()}</span>
          )}
          {row.getValue() === "Reject" && (
            <span className="badge badge-danger">{row.getValue()}</span>
          )}
        </>
      ),
    },
    {
      name: "SurveyDate",
      header: "TANGGAL DIBUAT",
      selector: (row) => row.getValue(),
    },
  ];

  return (
    <>
      {/* BUTTON GROUP */}
      <div className="row mb-3">
        <div className="col-lg-4">
          <button
            onClick={() => history.push(`/survey/${customid}/add`)}
            className="btn btn-success"
            disabled={isDisabledByYear}
          >
            <i className="fa fa-plus mr-1" /> Tambah Survey
          </button>
        </div>
        <div className="col-lg-8 text-right">
          <button
            onClick={() => onModalFilter()}
            className="btn btn-warning mr-2"
            disabled={dataSurveys?.length === 0}
          >
            <i className="fa fa-filter mr-1" /> Filter
          </button>
          <div className="btn-group" role="group">
            <Dropdown as="div">
              <Dropdown.Toggle
                variant="success"
                as="button"
                id="dropdown-basic"
                className="btn btn-info"
                disabled={dataSurveys?.length === 0}
              >
                <i className="fa fa-download mr-1" /> Download
              </Dropdown.Toggle>
              <Dropdown.Menu
                as="ul"
                className="dropdown-menu dropdown-menu-right"
              >
                <button
                  className="dropdown-item text-sm"
                  onClick={() => onModal()}
                >
                  <i className="fa fa-file-excel-o mr-1" /> Laporan Excel
                </button>
                <button className="dropdown-item" onClick={() => onModalFile()}>
                  <i className="fa fa-file-image-o mr-1" /> Aset File
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* TABEL */}
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card planned_task">
            <div className="header">
              <h2>Tabel Data</h2>
              {formSurvey?.filterId && (
                <div className="float-right">
                  <div className="badge badge-dark">
                    Filter: {formSurvey?.filterId[0].label} "
                    {formSurvey?.filterValue}"
                  </div>
                  <span className="text-danger" onClick={btnResetFilter}>
                    <i className="fa fa-close" />
                  </span>
                </div>
              )}
            </div>
            <div className="body">
              <DataTableSsr
                column={jsonColumn}
                data={dataSurveys}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                setSelectIndex={setSelectIndex}
                selectIndex={selectIndex}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal size={""} show={showModal} onHide={onModal}>
        <form onSubmit={handleSubmitDownload(btnDownloadExcel)}>
          <Modal.Header closeButton>
            <Modal.Title>Download Excel</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <Controller
                  control={controlDownload}
                  name={`atributId`}
                  render={({ field }) => (
                    <ComboboxRerender
                      label={"Berdasarkan (Opsional)"}
                      options={jsonFilterDownloads}
                      values={field.value}
                      onChange={(val) => {
                        field.onChange(val);
                        setValueDownload("atributId", val, {
                          shouldValidate: true,
                        });
                      }}
                      isError={errorsDownload.atributId}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />

                {getValuesDownload("atributId")?.[0]?.value !== "kecamatan" &&
                getValuesDownload("atributId")?.[0]?.value !== "desa" ? (
                  <></>
                ) : (
                  <>
                    <Controller
                      control={controlDownload}
                      name={`atributValueWilKec`}
                      render={({ field }) => (
                        <ComboboxRerender
                          label={"Pilih Kecamatan"}
                          options={dataWilKecamatans}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(actGetDesas(val[0].value));
                            setValueDownload("atributValueWilDesa", []);
                          }}
                          isError={errorsDownload.atributValueWilKec}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {getValuesDownload("atributId")?.[0]?.value === "desa" && (
                      <Controller
                        control={controlDownload}
                        name={`atributValueWilDesa`}
                        render={({ field }) => (
                          <ComboboxRerender
                            label={"Pilih Desa"}
                            options={dataWilDesas}
                            values={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            isError={errorsDownload.atributValueWilDesa}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <Controller
                  control={controlDownload}
                  name={`minDate`}
                  render={({ field }) => (
                    <TextDate
                      label={"Tanggal Mulai"}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="dd-MM-yyyy"
                      minDate={dataJenisSurveyById?.Tanggalmulai}
                      customInput={
                        <ReactInputMask
                          mask="99-99-9999"
                          onChange={field.onChange}
                          value={field.value}
                          maskChar=""
                        />
                      }
                      isError={errorsDownload.minDate}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  control={controlDownload}
                  name={`maxDate`}
                  render={({ field }) => (
                    <TextDate
                      label={"Tanggal Selesai"}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="dd-MM-yyyy"
                      maxDate={dataJenisSurveyById?.Tanggalselesai}
                      customInput={
                        <ReactInputMask
                          mask="99-99-9999"
                          onChange={field.onChange}
                          value={field.value}
                          maskChar=""
                        />
                      }
                      isError={errorsDownload.maxDate}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              onClick={() => resetDownload()}
              className="btn btn-danger"
            >
              <i className="fa fa-close"></i> <span>Reset</span>
            </button>
            <button type="submit" className="btn btn-info">
              <i className="fa fa-save"></i> <span>Download File</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal size={""} show={showModalFile} onHide={onModalFile}>
        <form onSubmit={handleSubmitFile(btnDownloadFiles)}>
          <Modal.Header closeButton>
            <Modal.Title>Download File</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <Controller
                  control={controlFile}
                  name={`atributId`}
                  render={({ field }) => (
                    <ComboboxRerender
                      label={"Berdasarkan (Opsional)"}
                      options={jsonFilterDownloads}
                      values={field.value}
                      onChange={(val) => {
                        field.onChange(val);
                        setValueFile("atributId", val, {
                          shouldValidate: true,
                        });
                      }}
                      isError={errorsFile.atributId}
                    />
                  )}
                  rules={{
                    required: false,
                  }}
                />

                {getValuesFile("atributId")?.[0]?.value !== "kecamatan" &&
                getValuesFile("atributId")?.[0]?.value !== "desa" ? (
                  <></>
                ) : (
                  <>
                    <Controller
                      control={controlFile}
                      name={`atributValueWilKec`}
                      render={({ field }) => (
                        <ComboboxRerender
                          label={"Pilih Kecamatan"}
                          options={dataWilKecamatans}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(actGetDesas(val[0].value));
                            setValueFile("atributValueWilDesa", []);
                          }}
                          isError={errorsFile.atributValueWilKec}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {getValuesFile("atributId")?.[0]?.value === "desa" && (
                      <Controller
                        control={controlFile}
                        name={`atributValueWilDesa`}
                        render={({ field }) => (
                          <ComboboxRerender
                            label={"Pilih Desa"}
                            options={dataWilDesas}
                            values={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            isError={errorsFile.atributValueWilDesa}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              onClick={() => resetFile()}
              className="btn btn-danger"
            >
              <i className="fa fa-close"></i> <span>Reset</span>
            </button>
            <button type="submit" className="btn btn-info">
              <i className="fa fa-save"></i> <span>Download File</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal size={""} show={showModalFilter} onHide={onModalFilter}>
        <form onSubmit={handleSubmitFilter(btnFilter)}>
          <Modal.Header closeButton>
            <Modal.Title>Filter Data</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <Controller
                  control={controlFilter}
                  name={`atributId`}
                  render={({ field }) => (
                    <ComboboxRerender
                      label={"Berdasarkan"}
                      options={dataSurveyAttribut}
                      values={field.value}
                      onChange={(val) => {
                        field.onChange(val);
                        setValueFilter("atributId", val, {
                          shouldValidate: true,
                        });
                      }}
                      isError={errorsFilter.atributId}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />

                {getValuesFilter("atributId")?.[0]?.value !== "kecamatan" &&
                getValuesFilter("atributId")?.[0]?.value !== "desa" ? (
                  <TextInput
                    label={"Kata Kunci"}
                    hook={registerFilter("atributValue", {
                      required: false,
                    })}
                    isError={errorsFilter.atributValue}
                  />
                ) : (
                  <>
                    <Controller
                      control={controlFilter}
                      name={`atributValueWilKec`}
                      render={({ field }) => (
                        <ComboboxRerender
                          label={"Pilih Kecamatan"}
                          options={dataWilKecamatans}
                          values={field.value}
                          onChange={(val) => {
                            field.onChange(val);
                            dispatch(actGetDesas(val[0].value));
                            setValueFilter("atributValueWilDesa", []);
                          }}
                          isError={errorsFilter.atributValueWilKec}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                    {getValuesFilter("atributId")?.[0]?.value === "desa" && (
                      <Controller
                        control={controlFilter}
                        name={`atributValueWilDesa`}
                        render={({ field }) => (
                          <ComboboxRerender
                            label={"Pilih Desa"}
                            options={dataWilDesas}
                            values={field.value}
                            onChange={(val) => {
                              field.onChange(val);
                            }}
                            isError={errorsFilter.atributValueWilDesa}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              onClick={() => resetFilter()}
              className="btn btn-danger"
            >
              <i className="fa fa-close"></i> <span>Reset</span>
            </button>
            <button type="submit" className="btn btn-info">
              <i className="fa fa-save"></i> <span>Mulai Filter</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

let jsonFilterDownloads = [
  {
    label: "Kecamatan",
    value: "kecamatan",
  },
  {
    label: "Desa",
    value: "desa",
  },
];

export default SectionTable;
