import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextArea, TextDate, TextInput } from "src/components/atoms";
import { Controller, useForm } from "react-hook-form";
import { actEditKuisioner, actGetKuisionerById } from "src/redux/actions";
import { useParams, useHistory } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { FUNCDateDmytoYmd } from "src/utils";

const SectionForm = () => {
  let params = useParams();
  let history = useHistory();
  const { dataKuisionerById } = useSelector((state) => state.kuisionerReducer);

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    dispatch(actGetKuisionerById(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    if (dataKuisionerById) {
      setValue("id", params.id);
      setValue("nama", dataKuisionerById?.Nama);
      setValue("namapendek", dataKuisionerById?.NamaPendek);
      setValue("deskripsi", dataKuisionerById?.Deskripsi);
      setValue(
        "tanggalmulai",
        new Date(FUNCDateDmytoYmd(dataKuisionerById?.Tanggalmulai))
      );
      setValue(
        "tanggalselesai",
        new Date(FUNCDateDmytoYmd(dataKuisionerById?.Tanggalselesai))
      );
    }
  }, [dataKuisionerById]);

  const btnSave = (data) => dispatch(actEditKuisioner(data));

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Kuisioner</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    label={"Nama"}
                    hook={register("nama", { required: true })}
                    isError={errors.nama}
                  />
                  <TextInput
                    label={"Nama Pendek"}
                    hook={register("namapendek", { required: true })}
                    isError={errors.namapendek}
                  />
                  <TextArea
                    label={"Deskripsi"}
                    hook={register("deskripsi", { required: true })}
                    isError={errors.deskripsi}
                  />
                </div>
                <div className="col-md-6">
                  <Controller
                    control={control}
                    name={`tanggalmulai`}
                    render={({ field }) => (
                      <TextDate
                        type="text"
                        label={"Tanggal Mulai"}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date(`${dataKuisionerById?.Tahun}-01-01`)}
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                        isError={errors.tanggalmulai}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  <Controller
                    control={control}
                    name={`tanggalselesai`}
                    render={({ field }) => (
                      <TextDate
                        type="text"
                        label={"Tanggal Selesai"}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        dateFormat="dd-MM-yyyy"
                        maxDate={
                          new Date(`${dataKuisionerById?.Tahun + 2}-12-31`)
                        }
                        customInput={
                          <ReactInputMask
                            mask="99-99-9999"
                            onChange={field.onChange}
                            value={field.value}
                            maskChar=""
                          />
                        }
                        isError={errors.tanggalselesai}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-times"></i> <span>Batalkan</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
