import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Combobox,
  TextArea,
  TextInput,
  TextUpload,
} from "src/components/atoms";
import { Controller, useForm } from "react-hook-form";
import {
  actUserAdd,
  actUserGetJenisFiles,
  actUserGetRoles,
} from "src/redux/actions";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/utils";

const SectionForm = () => {
  const { dataRoles, dataUserJenisFiles } = useSelector(
    (state) => state.userReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actUserGetRoles());
    dispatch(actUserGetJenisFiles());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  const onFile = (val, item, index) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target, ["png", "jpg"]);
    let file = {
      type: item.value,
      title: item.label,
      fileUpload: val.target.files[0],
    };
    setValue(`files[${index}]`, file, { shouldValidate: true });
  };

  const btnSave = (data) => dispatch(actUserAdd(data));

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Pengguna</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    label={"Username"}
                    hook={register("username", { required: true })}
                    isError={errors.username}
                  />
                  <TextInput
                    type="email"
                    label={"Email"}
                    hook={register("email", { required: true })}
                    isError={errors.email}
                  />
                  <div className="row">
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Depan"}
                        hook={register("firstName", { required: true })}
                        isError={errors.firstName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Tengah"}
                        hook={register("middleName", { required: false })}
                        isError={errors.middleName}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextInput
                        label={"Nama Belakang"}
                        hook={register("lastName", { required: false })}
                        isError={errors.lastName}
                      />
                    </div>
                  </div>
                  <TextArea
                    label={"Alamat Tinggal"}
                    hook={register("address", { required: true })}
                    isError={errors.address}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    type="number"
                    label={"Nomor Telepon"}
                    hook={register("phoneNumber", { required: false })}
                    isError={errors.phoneNumber}
                  />
                  <TextInput
                    type="number"
                    label={"Nomor Handphone"}
                    hook={register("mobileNumber", { required: false })}
                    isError={errors.mobileNumber}
                  />
                  <Controller
                    control={control}
                    name="roles"
                    render={({ field, value }) => (
                      <Combobox
                        label={"Role Akun"}
                        options={dataRoles}
                        values={value}
                        multi={true}
                        isError={errors.roles}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {dataUserJenisFiles?.map((item, index) => {
                    return (
                      <Controller
                        key={index}
                        control={control}
                        name={`files[${index}]`}
                        render={() => (
                          <TextUpload
                            label={item.label}
                            name={`files[${index}]`}
                            fileSelect={
                              getValues(`files[${index}]`)?.fileUpload?.name
                            }
                            onChange={(val) => {
                              onFile(val, item, index);
                            }}
                            onDel={() => setValue(`files[${index}]`, null)}
                            isError={errors.files?.[index]}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    );
                  })}
                </div>

                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Reset</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
