/* eslint-disable import/no-anonymous-default-export */
import { instanceAuth } from "src/utils";

class KuisionerService {
  // SECTION KUISIONER REF
  getRefKuisioners() {
    return instanceAuth.get("/kuisioner-ref/GetRefKuisioners");
  }
  getKuisioners() {
    return instanceAuth.get("/kuisioner-ref/GetKuisioners");
  }
  getKuisioner(id) {
    return instanceAuth.get(`/kuisioner-ref/GetKuisioner?id=${id}`);
  }
  addKuisioner(data) {
    return instanceAuth.post("/kuisioner-ref/AddKuisioner", data);
  }
  editKuisioner(data) {
    return instanceAuth.post("/kuisioner-ref/EditKuisioner", data);
  }
  setAktifKuisioner(data) {
    return instanceAuth.post("/kuisioner-ref/SetAktifKuisioner", data);
  }
  setTidakAktifKuisioner(data) {
    return instanceAuth.post("/kuisioner-ref/SetTidakAktifKuisioner", data);
  }
  getJenisFiles(id) {
    return instanceAuth.get(`/kuisioner-ref/GetJenisFiles?kuisionerId=${id}`);
  }
  getAttributKuisioner(id) {
    return instanceAuth.get(
      `/kuisioner-ref/GetAttributKuisioner?kuisionerId=${id}`
    );
  }
  getTemplateKuisioner(id) {
    return instanceAuth.get(
      `/kuisioner-ref/GetTemplateKuisioner?kuisionerId=${id}`
    );
  }

  // SECTION KUISIONER
  addResponden(data) {
    return instanceAuth.post("/kuisioner/RespondenAdd", data, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }
  deleteResponden(id) {
    return instanceAuth.post(`/kuisioner/RespondenDelete?id=${id}`);
  }
  editResponden(data) {
    return instanceAuth.post("/kuisioner/RespondenEdit", data, {
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
  }
  updateKuesioner(id, data) {
    return instanceAuth.post(
      `/kuisioner/KuisionerUpdate?respondenId=${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      }
    );
  }
  getRespondens(id, pageNum = 1, rows = 10) {
    return instanceAuth.get(
      `/kuisioner/GetRespondens?kuisionerId=${id}&pageNum=${pageNum}&rows=${rows}`
    );
  }
  getRespondensSearchBy(id, pageNum = 1, rows = 10, atributId, atributValue) {
    return instanceAuth.get(
      `/kuisioner/GetRespondensSearchBy?kuisionerId=${id}&pageNum=${pageNum}&rows=${rows}&atributId=${atributId}&atributValue=${atributValue}`
    );
  }
  getRespondenById(id) {
    return instanceAuth.get(`/kuisioner/GetRespondenById?id=${id}`);
  }
  downloadKuisionersFiles(id, atributId = "", atributValue = "") {
    return instanceAuth.get(
      `/kuisioner/DownloadKuisionerFiles?kuisionerId=${id}&atributId=${atributId}&atributValue=${atributValue}`,
      {
        responseType: "arraybuffer",
      }
    );
  }
  downloadKuisionersExcel(
    id,
    minDate,
    maxDate,
    atributId = "",
    atributValue = ""
  ) {
    return instanceAuth.get(
      `/kuisioner/DownloadKuisionersExcel?kuisionerId=${id}&minDate=${minDate}&maxDate=${maxDate}&atributId=${atributId}&atributValue=${atributValue}`,
      {
        responseType: "arraybuffer",
      }
    );
  }
}

export default new KuisionerService();
