import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { WidgetCount } from "src/components/atoms";
import { actGetCharts } from "src/redux/actions";

const SectionStatistic = () => {
  const dataGetCharts = useSelector((state) => state.homeReducer.dataGetCharts);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actGetCharts());
  }, [dispatch]);

  return (
    <>
      <div className="row clearfix">
        <div className="col-md-4">
          <WidgetCount
            heading={"Total Jenis Survey"}
            count={dataGetCharts?.TotalJenisSurvey}
            icon={
              <i
                className="icon-pointer text-info"
                style={{ fontSize: "50px" }}
              />
            }
          />
        </div>
        <div className="col-md-4">
          <WidgetCount
            heading={"Total Jenis Kuisioner"}
            count={dataGetCharts?.TotalJenisKuisioner}
            icon={
              <i
                className="icon-note text-warning"
                style={{ fontSize: "50px" }}
              />
            }
          />
        </div>
        <div className="col-md-4">
          <WidgetCount
            heading={"Total Pengguna Aktif"}
            count={dataGetCharts?.TotalUser}
            icon={
              <i
                className="icon-user text-success"
                style={{ fontSize: "50px" }}
              />
            }
          />
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="header">
              <h2>Daftar Survey</h2>
            </div>
            <div className="body">
              <div style={{ overflowY: "auto", maxHeight: "30vh" }}>
                <ul className="list-unstyled feeds_widget mr-2">
                  {dataGetCharts?.ChartSurveys?.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="feeds-left">
                          <i className="icon-pointer text-info" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            {item.NamaPendek}
                            <small className="float-right text-muted">
                              <span className="badge badge-primary">
                                Total Data: {item.Count}
                              </span>
                            </small>
                          </h4>
                          <small>{item.Nama}</small>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="header">
              <h2>Daftar Kuisioner</h2>
            </div>
            <div className="body">
              <div style={{ overflowY: "auto", maxHeight: "30vh" }}>
                <ul className="list-unstyled feeds_widget mr-2">
                  {dataGetCharts?.ChartKuisioners?.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="feeds-left">
                          <i className="icon-note text-warning" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            {item.NamaPendek}
                            <small className="float-right text-muted">
                              <span className="badge badge-primary">
                                Total Data: {item.Count}
                              </span>
                            </small>
                          </h4>
                          <small>{item.Nama}</small>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionStatistic;
