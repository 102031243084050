import { instanceAuth } from "src/utils";

class RefService {
  //API BARU REFERENSI SURVEY UMUM
  // getJenisSurveys() {
  //   return instanceAuth.get("/survey-ref/getJenisSurveys");
  // }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RefService();
