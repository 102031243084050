import { FUNCFormatDate, FUNCHandleRequest, loadingBar } from "src/utils";
import { SurveyService } from "src/services";
import Swal from "sweetalert2/dist/sweetalert2";

export const actFormSurvey = (formType, formValue) => {
  return { type: "FORM_SURVEY", formType, formValue };
};

export const actDownloadFilesSurvey = (tipe, iData) => {
  return (dispatch) => {
    let atributId, atributValue;
    if (iData.atributId) {
      atributId = iData.atributId[0].value;
      atributValue = iData.atributValue;
      if (iData.atributId[0].value === "kecamatan") {
        atributValue = iData.atributValueWilKec[0].label;
      } else if (iData.atributId[0].value === "desa") {
        atributValue = iData.atributValueWilDesa[0].label;
      }
    }
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.downloadSurveysFiles(tipe, atributId, atributValue).then(
        (res) => {
          if (res.headers["content-type"] !== "application/json;") {
            loadingBar(false);
            const blob = new Blob([res.data], {
              type: "application/octet-stream",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `export_data_file_survey_${new Date().getDate()}_${
                new Date().getMonth() + 1
              }_${new Date().getFullYear()}_${new Date().getTime()}.zip`
            );
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            loadingBar(false);
            const textData = new TextDecoder("utf-8").decode(res.data);
            const jsonData = JSON.parse(textData);
            Swal.fire("Gagal", `${jsonData.ReturnMessage}`, "error");
          }
        }
      )
    );
  };
};

export const actDownloadExcelSurvey = (tipe, iData) => {
  return (dispatch) => {
    let atributId, atributValue;
    if (iData.atributId) {
      atributId = iData.atributId[0].value;
      atributValue = iData.atributValue;
      if (iData.atributId[0].value === "kecamatan") {
        atributValue = iData.atributValueWilKec[0].label;
      } else if (iData.atributId[0].value === "desa") {
        atributValue = iData.atributValueWilDesa[0].label;
      }
    }
    var minDate = FUNCFormatDate(iData.minDate);
    var maxDate = FUNCFormatDate(iData.maxDate);
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.downloadSurveysExcel(
        tipe,
        minDate,
        maxDate,
        atributId,
        atributValue
      ).then((res) => {
        if (res.headers["content-type"] !== "application/json;") {
          loadingBar(false);
          const blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Laporan_survey_${new Date().getDate()}_${
              new Date().getMonth() + 1
            }_${new Date().getFullYear()}_${new Date().getTime()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          loadingBar(false);
          const textData = new TextDecoder("utf-8").decode(res.data);
          const jsonData = JSON.parse(textData);
          Swal.fire("Gagal", `${jsonData.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSurveys = (id, pageNum, rows) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SURVEYS", payload: [] });
    FUNCHandleRequest(() =>
      SurveyService.getSurveys(id, pageNum, rows).then((res) => {
        const data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_SURVEYS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "data not found") {
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
          }
        }
      })
    );
  };
};

export const actGetSurveysSearchBy = (
  id,
  pageNum,
  rows,
  atributId,
  atributValue
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_SURVEYS", payload: [] });
    FUNCHandleRequest(() =>
      SurveyService.getSurveysSearchBy(
        id,
        pageNum,
        rows,
        atributId,
        atributValue
      ).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_SURVEYS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "data not found")
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetSurvey = (id, history = null, jenisSurveyId, type) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.getSurvey(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          loadingBar(false);
          dispatch({ type: "DATA_SURVEY", payload: data.Data });
          if (history) history.push(`/survey/${jenisSurveyId}/${type}/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetAttributSurvey = (id) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      SurveyService.getSearchAtributs(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          loadingBar(false);
          var array = [];
          data.Data.map((v, i) =>
            array.push({
              ...v,
              value: v.Id,
              label: v.Name || v.Nama,
            })
          );
          array.push({
            label: "Kecamatan",
            value: "kecamatan",
          });
          array.push({
            label: "Desa",
            value: "desa",
          });
          dispatch({
            type: "DATA_SURVEY_ATTRIBUT",
            payload: array,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetTemplateSurvey = (id) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      SurveyService.getTemplateSurvey(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_SURVEY_TEMPLATE", payload: data.Data });
          dispatch({
            type: "DATA_SDI_ATTR_TEMPLATE_SURVEY",
            payload: data.Data.Atributs,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actSurveyAdd = (iData, tipe) => {
  return () => {
    loadingBar(true);
    iData = JSON.stringify(iData);
    FUNCHandleRequest(() =>
      SurveyService.addSurvey(iData).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", "Survey berhasil ditambah!", "success").then(
            () => (window.location = `/survey/${tipe}/list`)
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actSurveyEdit = (iData, tipe) => {
  return () => {
    loadingBar(true);
    iData = JSON.stringify(iData);
    FUNCHandleRequest(() =>
      SurveyService.editSurvey(iData).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", "Survey berhasil diperbarui!", "success").then(
            () => (window.location = `/survey/${tipe}/list`)
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actSurveyDelete = (id, jenisSurveyId) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus data survey ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);

        FUNCHandleRequest(() =>
          SurveyService.deleteSurvey(id).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Data survey berhasil dihapus!",
                "success"
              ).then(() => {
                dispatch(actGetSurveys(jenisSurveyId, 1, 10));
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};
