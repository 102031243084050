import { HomeService } from "src/services";
import { FUNCHandleRequest } from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

export const actGetMap = () => {
  return (dispatch) => {
    dispatch({ type: "DATA_GET_MAP", payload: null });
    FUNCHandleRequest(() =>
      HomeService.getMap().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_GET_MAP", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetCharts = () => {
  return (dispatch) => {
    dispatch({ type: "DATA_GET_CHARTS", payload: [] });

    FUNCHandleRequest(() =>
      HomeService.getCharts().then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_GET_CHARTS", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
