import React from "react";
import { useDispatch } from "react-redux";
import { Combobox, TextArea, TextInput } from "src/components/atoms";
import { Controller, useForm } from "react-hook-form";
import { actAddLayer } from "src/redux/actions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const SectionForm = () => {
  const dispatch = useDispatch();
  let params = useParams();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("groupId", params.groupid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const btnSave = (data) => dispatch(actAddLayer(data));

  let jsonTypes = [
    {
      label: "BASEMAP",
      value: "basemap",
    },
    {
      label: "WMS",
      value: "wms",
    },
  ];
  let jsonStatuss = [
    {
      label: "Aktif",
      value: 1,
    },
    {
      label: "Non-Aktif",
      value: -1,
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Form Map Layer</h2>
          </div>
          <div className="body">
            <form onSubmit={handleSubmit(btnSave)}>
              <div className="row">
                <div className="col-md-6">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field, value }) => (
                      <Combobox
                        label={"Tipe Layer"}
                        options={jsonTypes}
                        values={value}
                        isError={errors.type}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  <TextInput
                    label={"Url"}
                    hook={register("url", { required: false })}
                    isError={errors.url}
                    exampleinfo={
                      "contoh jika wms: http://domaingeoserver.com/geoserver/muba_spasial/ows"
                    }
                  />
                  <TextInput
                    label={"Nama (*nama isi dari url)"}
                    hook={register("name", { required: true })}
                    isError={errors.name}
                    exampleinfo={
                      "contoh jika wms: muba_spasial:adm_kecamatan_ar"
                    }
                  />
                  <TextInput
                    label={"Judul (*judul yang akan tampil di map)"}
                    hook={register("title", { required: true })}
                    isError={errors.title}
                  />
                </div>
                <div className="col-md-6">
                  <TextArea
                    label={"Deskripsi"}
                    hook={register("description", { required: true })}
                    isError={errors.description}
                  />
                  <Controller
                    control={control}
                    name="status"
                    render={({ field, value }) => (
                      <Combobox
                        label={"Status Layer"}
                        options={jsonStatuss}
                        values={value}
                        isError={errors.status}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Reset</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
