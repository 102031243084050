import React from "react";
import { LayoutClient } from "src/components/molecules";

const Blank = () => {
  return (
    <LayoutClient
      page="Survey BTS"
      breadcrumb={[{ name: "Page", navigate: "" }]}
    >
      {/* Content Here */}
    </LayoutClient>
  );
};

export default Blank;
