import { useEffect, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { Pagination } from "../pagination";
import { IMGNotFound } from "src/assets/images/dummy";

export const DataTable = ({ data = [], column = [], isPagging = true, isSearch = true }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const columnHelper = createColumnHelper();
  var columns = [];
  column.forEach((item) => {
    const columnConfig = {
      header: () => item.header,
      cell: (info) => (!item.selector ? info.getValue() : item.selector(info)),
      filterFn: "fuzzy",
    };
    columns.push(columnHelper.accessor(item.name, columnConfig));
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });
  useEffect(() => {
    if (!isPagging) {
      // Set page size to 100 when pagination is disabled
      table.setPageSize(100);
    } else {
      // Set page size to 10 when pagination is enabled
      table.setPageSize(10);
    }
  }, [isPagging, table]); // Run effect when isPagging or table changes

  return (
    <>
      <div className="flex flex-col">
        <div className="row align-items-center mb-3">
          {isPagging && (
            <div className="col-md-2 d-flex align-items-center">
              Show
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="form-control mx-2 text-xs"
                style={{ width: "61px" }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              entries
            </div>
          )}
          {isSearch && (
            <div className="col-md-10 d-flex justify-content-end">
              <div className="col-md-4">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input
                    aria-describedby="basic-addon1"
                    aria-label="Search"
                    className="form-control"
                    type="text"
                    placeholder="Masukan Kata Kunci..."
                    value={globalFilter ?? ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} scope="col">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {table.getRowModel().rows.length === 0 && (
            <center>
              <img
                src={IMGNotFound}
                alt="Not Found"
                width={200}
                height={200}
                className="img-fluid"
              />
              <p>Data tidak ada</p>
            </center>
          )}
        </div>
        {isPagging && (
          <div className="row align-items-center">
            <div className="col-md-3">
              <p>
                Showing {table.getState().pagination.pageIndex + 1} to{" "}
                {table.getState().pagination.pageSize} of {table.getPageCount()}{" "}
                entries
              </p>
            </div>

            <div className="col-md-9 d-flex justify-content-end">
              <PaginationCategory
                pageCount={table.getPageCount()}
                pageIndex={table.getState().pagination.pageIndex}
                setPageIndex={table.setPageIndex}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function PaginationCategory({ setPageIndex, pageIndex, pageCount }) {
  return (
    <Pagination
      gotoPage={setPageIndex}
      canPreviousPage={pageIndex > 0}
      canNextPage={pageIndex < pageCount - 1}
      pageCount={pageCount}
      pageIndex={pageIndex}
    />
  );
}
