import React from "react";
import { LayoutClient } from "src/components/molecules";
import SectionTable from "./parts/SectionTable";

const ManageKuisionerList = () => {
  return (
    <LayoutClient
      page="Manajemen Kuisioner"
      breadcrumb={[{ name: "Manajemen Kuisioner", navigate: "" }]}
    >
      <SectionTable />
    </LayoutClient>
  );
};

export default ManageKuisionerList;
