import React, { useEffect } from "react";
import { LayoutClient } from "src/components/molecules";
import SectionTable from "./parts/SectionTable";
import { useDispatch, useSelector } from "react-redux";
import {
  actGetAttributSurvey,
  actGetJenisSurveyById,
  actGetWilReferences,
} from "src/redux/actions";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FUNCDateDmytoYmd } from "src/utils";

const SurveyList = () => {
  let { customid } = useParams();
  const jenisSurveyId = customid;
  const hookForm = useForm();
  const { setValue } = hookForm;
  const dataJenisSurveyById = useSelector(
    (state) => state.refReducer.dataJenisSurveyById
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actGetJenisSurveyById(jenisSurveyId));
    dispatch(actGetWilReferences());
    dispatch(actGetAttributSurvey(jenisSurveyId));
  }, [dispatch, jenisSurveyId]);

  useEffect(() => {
    if (dataJenisSurveyById) {
      setValue(
        "minDate",
        new Date(FUNCDateDmytoYmd(dataJenisSurveyById?.Tanggalmulai))
      );
      setValue(
        "maxDate",
        new Date(FUNCDateDmytoYmd(dataJenisSurveyById?.Tanggalselesai))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataJenisSurveyById]);

  let isDisabledByYear =
    dataJenisSurveyById?.Tahun !== new Date().getFullYear();
  return (
    <>
      <LayoutClient
        page={`Data ${dataJenisSurveyById?.Namapendek}`}
        breadcrumb={[{ name: dataJenisSurveyById?.Namapendek, navigate: "" }]}
      >
        <div className="alert alert-primary">
          <b>{dataJenisSurveyById?.Nama}</b>
          <p className="mb-0">{dataJenisSurveyById?.Deskripsi}</p>
        </div>
        {isDisabledByYear && (
          <div className="alert alert-danger">
            <b>Diarsipkan!</b>
            <p className="mb-0">
              Data telah melewati batas tahun yang ditentukan, yaitu tahun{" "}
              <u>{dataJenisSurveyById?.Tahun}</u>. Oleh karena itu, data
              tersebut tidak dapat diubah lagi. Namun, Anda masih dapat
              mengunduh file data tersebut.
            </p>
          </div>
        )}

        <SectionTable isDisabledByYear={isDisabledByYear} />
      </LayoutClient>
    </>
  );
};

export default SurveyList;
