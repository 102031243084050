import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable, DataTableSsr } from "src/components/molecules";
import {
  actGetKecamatans,
  actGetDataMonitorings,
  actGetDataSubMonitorings,
  resetDataMonitorings,
  resetDataSubMonitorings
} from "src/redux/actions";
import { Controller, useForm } from "react-hook-form";
import { Combobox } from "src/components/atoms";

const SectionTable = () => {
  const {
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();
  const { dataWilKecamatans } = useSelector(
    (state) => state.wilReducer
  );

  useEffect(() => {
    dispatch(resetDataMonitorings());
    dispatch(resetDataSubMonitorings());
    dispatch(actGetKecamatans("b0fb1fc8-9ace-46b4-bc8a-f0106c3a87ca"));
  }, [dispatch]);


  const { dataMonitoringKecamatans, dataSubMonitorings } = useSelector(
    (state) => state.monitoringReducer
  );
  const currentYear = new Date().getFullYear();
  const cmbTahuns = Array.from({ length: 5 }, (v, i) => ({
    value: currentYear - i,
    label: (currentYear - i).toString(),
  }));
  const [selectedKecamatan, setSelectedKecamatan] = useState(null);
  const [selectedKecamatanNama, setSelectedKecamatanNama] = useState(null);
  const [selectedTahun, setSelectedTahun] = useState(null);

  const searchData = (idKecamatan, tahun) => {
    dispatch(resetDataSubMonitorings());
    const selectedKecamatan = idKecamatan ?? dataWilKecamatans[0].value;
    const selectedTahun = tahun ?? cmbTahuns[0].value;
    dispatch(actGetDataMonitorings(selectedKecamatan, selectedTahun));
  };


  const handleViewDetails = (row) => {
    console.log(selectedKecamatan);
    console.log(row.getValue());
    console.log(selectedTahun);
    const tahun = selectedTahun ?? cmbTahuns[0].value;;
    dispatch(actGetDataSubMonitorings(selectedKecamatan, row.getValue(), tahun));
    //    console.log(dataSubMonitorings);
    //    console.log(dataSubMonitorings.Nama);
  };

  const jsonColumn = [
    {
      name: "Kode",
      header: "Aksi",
      selector: (row) => (
        <>
          <button
            onClick={() => handleViewDetails(row)}
            className="btn btn-sm btn-info mr-1"
          >
            View Details
          </button>
        </>
      ),
    },
    {
      name: "Nama",
      header: "Kuisioner/Survey"
    },
    {
      name: "JumlahDesa",
      header: "Jumlah Desa/Kelurahan",
    },
    {
      name: "JumlahData",
      header: "Jumlah Data",
    }
  ];

  const jsonDetailColumn = [
    {
      name: "Id",
      header: "ID",
    },
    {
      name: "Nama",
      header: "Nama Desa",
    },
    {
      name: "JumlahData",
      header: "Jumlah Data",
    },
  ];

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card planned_task">
          <div className="header">
            <h2>Tabel Data</h2>
          </div>

          <div className="body">
            <div className="flex flex-col">
              <div className="col-md-10 d-flex justify-between">
                {dataWilKecamatans && (
                  <>
                    <div className="flex-1 mr-2">
                      <Controller
                        control={control}
                        name="monitoringLeft"
                        render={({ field, value }) => (
                          <Combobox
                            label={"Kecamatan"}
                            options={dataWilKecamatans}
                            values={
                              selectedKecamatan
                                ? dataWilKecamatans.filter((item) => item.value === selectedKecamatan) // Set the current selection
                                : []
                            }
                            isError={errors.surveys}
                            onChange={(val) => {
                              if (val && val.length > 0) {  // Ensure val exists and has at least one item
                                setSelectedKecamatan(val[0].value); // Update 'kecamatan' state with the selected value
                                setSelectedKecamatanNama(val[0].Nama); // Update the state with the selected name
                                searchData(val[0].value, selectedTahun); // Trigger data search based on selection
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <Controller
                        control={control}
                        name="monitoringRight"
                        render={({ field, value }) => (
                          <Combobox
                            label={"Tahun"}
                            options={cmbTahuns}
                            values={selectedTahun
                              ? cmbTahuns.filter((item) => item.value === selectedTahun) // Find object with the selected value
                              : []} // Set selected value
                            isError={errors.surveys}
                            onChange={(val) => {
                              setSelectedTahun(val[0].value); // Update 'tahun' state
                              searchData(selectedKecamatan, val[0].value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {dataMonitoringKecamatans ? (
              <DataTable data={dataMonitoringKecamatans} column={jsonColumn} isPagging={false} isSearch={false} />
            ) : (
              <div>
                <DataTable column={jsonColumn} isPagging={false} isSearch={false} />
              </div>
            )}

            {dataSubMonitorings && dataSubMonitorings.Detail ? (
              <div className="mt-4">
                <h4>
                  Detail Data {dataSubMonitorings.Nama && `: ${dataSubMonitorings.Nama}`} {selectedKecamatanNama}
                </h4>
                <DataTable data={dataSubMonitorings.Detail} column={jsonDetailColumn} isPagging={false} isSearch={false} />
              </div>
            ) : (
              <div className="mt-4">
                <h4>Detail Data</h4>
                <DataTable column={jsonDetailColumn} isPagging={false} isSearch={false} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTable;

//{dataSubMonitorings && (
//  <div className="mt-4">
//    <h3>Detail Data</h3>
//    <DataTable data={dataSubMonitorings} column={jsonDetailColumn} isPagging={false} isSearch={false} />
//  </div>
//)}        