import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextArea, TextInput, TextUpload } from "src/components/atoms";
import { useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  actRespondenAdd,
  actGetTemplateKuisioner,
  actGetKuisionerById,
} from "src/redux/actions";
import {
  FUNCFileConvertBase64,
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/utils";
import { Modal } from "react-bootstrap";
import { MapContainerDragPoint } from "src/components/organisms";
import Swal from "sweetalert2/dist/sweetalert2";

const SectionForm = (props) => {
  const { customid } = useParams();

  let jenisKuisionerId = customid;

  const { dataKuisionerById, dataKuisionerTemplate } = useSelector(
    (state) => state.kuisionerReducer
  );
  const [showModalMap, setShowModalMap] = useState(false);

  const dispatch = useDispatch();

  const hookForm = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = hookForm;

  const { fields: fieldsFiles, append: appendFiles } = useFieldArray({
    control,
    name: "Files",
  });

  useEffect(() => {
    reset();
    dispatch(actGetKuisionerById(jenisKuisionerId));
    dispatch(actGetTemplateKuisioner(jenisKuisionerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, jenisKuisionerId]);

  useEffect(() => {
    dataKuisionerTemplate?.Files?.forEach((item) => {
      appendFiles(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKuisionerTemplate]);

  const onModalMap = () => {
    setShowModalMap(!showModalMap);
  };

  const savePointLatLon = () => {
    if (!getValues("latitudeUpdate")) {
      Swal.fire("Gagal", "Anda tidak melakukan perubahan lokasi!", "info");
      return;
    }
    setValue("latitude", getValues("latitudeUpdate"));
    setValue("longitude", getValues("longitudeUpdate"));
    onModalMap();
  };

  const btnSave = (data) => {
    let files = data?.Files?.map((item) =>
      Object.assign(item, {
        Nama: item.Base64 || item.Nama,
      })
    );
    let dataArr = Object.assign(dataKuisionerTemplate, {
      Kodedata: data.kodeData,
      Kuisionerid: jenisKuisionerId,
      Longitude: data.longitude || 0,
      Latitude: data.latitude || 0,
      Nama: data.nama,
      Email: data.email,
      Notelpon: data.notelpon,
      Nohandphone: data.nohandphone,
      Alamat: data.alamat,
      Catatan: data.catatan,
      Files: files,
    });
    dispatch(actRespondenAdd(dataArr, jenisKuisionerId));
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card planned_task">
            <div className="header">
              <h2>Form Responden {dataKuisionerById?.NamaPendek}</h2>
            </div>
            <div className="body">
              <form onSubmit={handleSubmit(btnSave)}>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      label={dataKuisionerById?.Ketkodedata?.toUpperCase()}
                      hook={register("kodeData", { required: false })}
                      isError={errors.kodeData}
                    />
                    <div className="row">
                      <div className="col-md-5">
                        <TextInput
                          label={"LONGITUDE"}
                          hook={register("longitude", { required: true })}
                          isError={errors.longitude}
                        />
                      </div>
                      <div className="col-md-5">
                        <TextInput
                          label={"LATITUDE"}
                          hook={register("latitude", { required: true })}
                          isError={errors.latitude}
                        />
                      </div>
                      <div className="col-md-2 d-flex items-align-center justify-content-center my-0">
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={() => onModalMap()}
                        >
                          <i className="fa fa-map"></i> <span>MAP</span>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextInput
                          label={"NAMA"}
                          hook={register("nama", { required: false })}
                          isError={errors.nama}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={"EMAIL"}
                          hook={register("email", { required: false })}
                          isError={errors.email}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextInput
                          label={"NO TELEPON"}
                          hook={register("notelpon", { required: false })}
                          isError={errors.notelpon}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={"NO HANDPHONE"}
                          hook={register("nohandphone", { required: false })}
                          isError={errors.nohandphone}
                        />
                      </div>
                    </div>
                    <TextArea
                      label={"ALAMAT"}
                      hook={register("alamat", { required: false })}
                      isError={errors.alamat}
                    />
                    <TextArea
                      label={"CATATAN"}
                      hook={register("catatan", { required: false })}
                      isError={errors.catatan}
                    />
                  </div>

                  <div className="col-md-6">
                    <ContainerDynamicFormUpload
                      data={fieldsFiles}
                      hookForm={hookForm}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-5">
                  <button
                    type="button"
                    className="btn btn-dark mr-2"
                    onClick={() => reset()}
                  >
                    <i className="fa fa-times"></i> <span>Reset</span>
                  </button>
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-save"></i> <span>Simpan</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal size={"xl"} show={showModalMap} onHide={onModalMap}>
        <Modal.Header closeButton>
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ height: "60vh" }}>
            <MapContainerDragPoint hookForm={hookForm} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            onClick={savePointLatLon}
          >
            <i className="fa fa-save"></i> <span>Simpan</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ContainerDynamicFormUpload = ({ data, hookForm }) => {
  return (
    <div className="row">
      {data?.map((item, index) => {
        return (
          <KuisionerFormUpload
            key={index}
            index={index}
            attribut={item}
            hookForm={hookForm}
          />
        );
      })}
    </div>
  );
};

const KuisionerFormUpload = ({ index, attribut, hookForm }) => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = hookForm;

  const onFile = async (val) => {
    FUNCValidateUploadFileSize(val.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(val.target, ["pdf", "png", "jpg", "jpeg"]);
    let fileUpload = val.target.files[0];
    let fileBase64 = await FUNCFileConvertBase64(fileUpload).then(
      (res) => res.split("base64,")[1]
    );
    let file = Object.assign(attribut, {
      JenisFileId: attribut.JenisFileId,
      Judul: attribut.JenisFile.Nama,
      FileUpload: fileUpload,
      Base64: fileBase64,
    });
    setValue(`Files[${index}]`, file, {
      shouldValidate: true,
    });
  };
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-6">
          <Controller
            control={control}
            name={`Files[${index}].FileUpload`}
            render={({ field }) => (
              <TextUpload
                label={attribut.JenisFile.Nama?.toUpperCase()}
                name={field.name}
                fileSelect={getValues(`Files[${index}]`)?.FileUpload?.name}
                onChange={(val) => {
                  onFile(val);
                }}
                onDel={() => setValue(`Files[${index}]`, undefined)}
                isError={errors?.Files?.[index]?.FileUpload}
              />
            )}
            rules={{
              required: false,
            }}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label={`CATATAN`}
            hook={register(`Files[${index}].Catatan`, {
              required: false,
            })}
            isError={errors?.Files?.[index]?.Catatan}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionForm;
