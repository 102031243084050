import { KuisionerService } from "src/services";
import {
  FUNCArraySelectId,
  FUNCArraySelectKode,
  FUNCDateToString,
  FUNCFormatDate,
  FUNCHandleRequest,
  loadingBar,
} from "src/utils";
import Swal from "sweetalert2/dist/sweetalert2";

import { actGetMenus } from "./userAction";

export const actFormKuisioner = (formType, formValue) => {
  return { type: "FORM_KUISIONER", formType, formValue };
};

//referensi kuisioner
export const actGetAttributKuisioner = (id) => {
  return (dispatch) => {
    FUNCHandleRequest(() =>
      KuisionerService.getAttributKuisioner(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SDI_ATTR_TEMPLATE_KUISIONER",
            payload: data.Data,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetTemplateKuisioner = (id) => {
  return (dispatch) => {
    dispatch({ type: "DATA_KUISIONER_TEMPLATE", payload: null });
    FUNCHandleRequest(() =>
      KuisionerService.getTemplateKuisioner(id).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_KUISIONER_TEMPLATE",
            payload: data.Data,
          });
          dispatch({
            type: "DATA_SDI_ATTR_TEMPLATE_KUISIONER",
            payload: data.Data,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetRefKuisioners = () => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.getRefKuisioners().then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_REF_JENIS_KUISIONERS",
            payload: FUNCArraySelectKode(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};
export const actGetKuisioners = () => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "DATA_KUISIONERS", payload: [] });
    FUNCHandleRequest(() =>
      KuisionerService.getKuisioners().then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_KUISIONERS",
            payload: data.Data,
          });

          var array = [];
          data.Data.map((v, i) =>
            array.push({
              value: v.Id,
              label: v.Name || v.Nama,
              ...v,
            })
          );
          dispatch({
            type: "DATA_SDI_JENIS_KUISIONERS",
            payload: array,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetKuisionerById = (id, history = null) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "DATA_KUISIONER_TEMPLATE", payload: null });
    FUNCHandleRequest(() =>
      KuisionerService.getKuisioner(id).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_KUISIONER_BY_ID", payload: data.Data });
          if (history) history.push(`/kuisioners/edit/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actSetAktifKuisioner = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan aktivasi kuisioner ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          KuisionerService.setAktifKuisioner(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Kuisioner berhasil di aktivasi!",
                "success"
              ).then(() => dispatch(actGetKuisioners()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actSetTidakAktifKuisioner = (id) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan deaktivasi kuisioner ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        var fd = new FormData();
        fd.append("id", id);
        FUNCHandleRequest(() =>
          KuisionerService.setTidakAktifKuisioner(fd).then((res) => {
            var data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Kuisioner berhasil di deaktivasi!",
                "success"
              ).then(() => dispatch(actGetKuisioners()));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actAddKuisioner = (kode) => {
  return (dispatch) => {
    var fd = new FormData();
    Swal.fire({
      title: "Tambah Kuisioner",
      text: `Anda akan membuat Kuisioner, dengan Kode Kuisioner: ${kode}`,
      inputLabel: "Masukan tahun:",
      input: "number",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Buat Kuisioner!",
      showLoaderOnConfirm: true,
      preConfirm: async (tahun) => {
        try {
          fd.append("tahun", tahun);
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);

        fd.append("refKuisionerKode", kode);
        FUNCHandleRequest(() =>
          KuisionerService.addKuisioner(fd).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Kuisioner berhasil dibuat!",
                "success"
              ).then(() => {
                dispatch(actGetKuisioners());
                dispatch(actGetMenus());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actEditKuisioner = (iData) => {
  return () => {
    loadingBar(true);
    var fd = new FormData();
    fd.append("id", iData.id);
    fd.append("nama", iData.nama);
    fd.append("namapendek", iData.namapendek);
    fd.append("deskripsi", iData.deskripsi);
    fd.append("tanggalmulai", FUNCDateToString(iData.tanggalmulai));
    fd.append("tanggalselesai", FUNCDateToString(iData.tanggalselesai));

    FUNCHandleRequest(() =>
      KuisionerService.editKuisioner(fd).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Kuisioner berhasil diperbarui!",
            "success"
          ).then(() => (window.location = "/kuisioners"));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetJenisFiles = (tipe) => {
  return (dispatch) => {
    dispatch({ type: "DATA_JENIS_FILES", payload: [] });
    FUNCHandleRequest(() =>
      KuisionerService.getJenisFiles(tipe).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_JENIS_FILES",
            payload: FUNCArraySelectId(data.Data),
          });
        } else {
          Swal.fire("Gagal", `${"Jenis files " + data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetRespondens = (tipe, pageNum, rows) => {
  return (dispatch) => {
    dispatch({ type: "DATA_RESPONDENS", payload: null });
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.getRespondens(tipe, pageNum, rows).then((res) => {
        loadingBar(false);
        var data = res.data;

        if (data.IsSuccess) {
          dispatch({ type: "DATA_RESPONDENS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "data not found")
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetRespondensSearchBy = (
  tipe,
  pageNum,
  rows,
  atributId,
  atributValue
) => {
  return (dispatch) => {
    dispatch({ type: "DATA_RESPONDENS", payload: null });
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.getRespondensSearchBy(
        tipe,
        pageNum,
        rows,
        atributId,
        atributValue
      ).then((res) => {
        loadingBar(false);
        var data = res.data;

        if (data.IsSuccess) {
          dispatch({ type: "DATA_RESPONDENS", payload: data.Data });
        } else {
          if (data.ReturnMessage !== "data not found")
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actGetRespondenById = (id, history = null, tipe, to) => {
  return (dispatch) => {
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.getRespondenById(id).then((res) => {
        loadingBar(false);
        var data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_RESPONDEN_BY_ID", payload: data.Data });
          if (history) history.push(`/kuisioner/${tipe}/${to}/${id}`);
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actRespondenAdd = (iData, tipe) => {
  return () => {
    loadingBar(true);
    iData = JSON.stringify(iData);
    FUNCHandleRequest(() =>
      KuisionerService.addResponden(iData).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire("Berhasil", "Responden berhasil disimpan!", "success").then(
            () => (window.location = `/kuisioner/${tipe}/list`)
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actRespondenEdit = (iData, tipe) => {
  return () => {
    loadingBar(true);
    iData = JSON.stringify(iData);
    FUNCHandleRequest(() =>
      KuisionerService.editResponden(iData).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Responden berhasil diperbarui!",
            "success"
          ).then(() => (window.location = `/kuisioner/${tipe}/list`));
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actRespondenUpdate = (id, iData, tipe) => {
  return () => {
    loadingBar(true);
    iData = JSON.stringify(iData);
    FUNCHandleRequest(() =>
      KuisionerService.updateKuesioner(id, iData).then((res) => {
        var data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            "Responden berhasil diperbarui!",
            "success"
          ).then(() => window.location.reload());
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actRespondenDelete = (id, tipe) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus data responden ini!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);

        FUNCHandleRequest(() =>
          KuisionerService.deleteResponden(id).then((res) => {
            var data = res.data;
            if (data?.IsSuccess) {
              Swal.fire(
                "Berhasil",
                "Data responden berhasil dihapus!",
                "success"
              ).then(() => dispatch(actGetRespondens(tipe)));
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          })
        );
      }
    });
  };
};

export const actDownloadFilesKuisioner = (tipe, iData) => {
  return (dispatch) => {
    let atributId, atributValue;
    if (iData.atributId) {
      atributId = iData.atributId[0].value;
      atributValue = iData.atributValue;
      if (iData.atributId[0].value === "kecamatan") {
        atributValue = iData.atributValueWilKec[0].label;
      } else if (iData.atributId[0].value === "desa") {
        atributValue = iData.atributValueWilDesa[0].label;
      }
    }
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.downloadKuisionersFiles(
        tipe,
        atributId,
        atributValue
      ).then((res) => {
        if (res.headers["content-type"] !== "application/json;") {
          loadingBar(false);
          const blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `export_data_file_kuisioner_${new Date().getDate()}_${
              new Date().getMonth() + 1
            }_${new Date().getFullYear()}_${new Date().getTime()}.zip`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          loadingBar(false);
          const textData = new TextDecoder("utf-8").decode(res.data);
          const jsonData = JSON.parse(textData);
          Swal.fire("Gagal", `${jsonData.ReturnMessage}`, "error");
        }
      })
    );
  };
};

export const actDownloadExcelKuisioner = (tipe, iData) => {
  return (dispatch) => {
    let atributId, atributValue;
    if (iData.atributId) {
      atributId = iData.atributId[0].value;
      atributValue = iData.atributValue;
      if (iData.atributId[0].value === "kecamatan") {
        atributValue = iData.atributValueWilKec[0].label;
      } else if (iData.atributId[0].value === "desa") {
        atributValue = iData.atributValueWilDesa[0].label;
      }
    }

    var minDate = FUNCFormatDate(iData.minDate);
    var maxDate = FUNCFormatDate(iData.maxDate);
    loadingBar(true);
    FUNCHandleRequest(() =>
      KuisionerService.downloadKuisionersExcel(
        tipe,
        minDate,
        maxDate,
        atributId,
        atributValue
      ).then((res) => {
        if (res.headers["content-type"] !== "application/json;") {
          loadingBar(false);
          const blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          //   const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `laporan_kuisioner_${new Date().getDate()}_${
              new Date().getMonth() + 1
            }_${new Date().getFullYear()}_${new Date().getTime()}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          loadingBar(false);
          const textData = new TextDecoder("utf-8").decode(res.data);
          const jsonData = JSON.parse(textData);
          Swal.fire("Gagal", `${jsonData.ReturnMessage}`, "error");
        }
      })
    );
  };
};
