import { instance } from "src/utils/instance";

class AuthService {
  login(data) {
    return instance.post("/auth/login", data);
  }
  refreshToken(token) {
    return instance.post(`/auth/refreshToken?refreshToken=${token}`);
  }
  register(data) {
    return instance.post("/auth/register", data);
  }
  forgotPassword(data) {
    return instance.post("/auth/forgotPassword", data);
  }
  forgotPasswordConfirm(token) {
    return instance.get(`/auth/forgotPasswordConfirm?token=${token}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
